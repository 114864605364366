import endpoint from 'src/apis/endpoint';
import createAxiosInstance from 'src/apis/axios';

const axiosInstance = createAxiosInstance({
  headers: {
    'Content-Type': 'application/json'
  }
});

const multiPartInstance = createAxiosInstance({
  headers: {
    'Content-Type': 'multipart/form-data'
  }
});

/*토큰 발급 API*/
export const getTokenApi = async (data) => {
  return await axiosInstance.post(endpoint.getToken, data).then((response) => response.data);
};

/*토큰 검증 API*/
export const validateTokenApi = async (data) => {
  return await axiosInstance.post(endpoint.tokenVerify, data).then((response) => response.data);
};

/*신분증 진위확인 API*/
export const doRmtIdVerApi = async (data) => {
  return await axiosInstance.post(endpoint.doRmtIdVer, data).then((response) => response.data);
};

/*얼굴인식 개인정보이용동의 업데이트 API*/
export const personalInfoConsentApi = async (data) => {
  return await axiosInstance.post(endpoint.personalInfo, data).then((response) => response.data);
};

/*얼굴인식 신분증 비교 인증 API*/
export const authenticateWithFaceApi = async (data) => {
  return await axiosInstance.post(endpoint.mkfrVerify, data).then((response) => response.data);
};

/*사용자 화면 얼굴인식 타임아웃 로그 적재 API*/
export const detectionFailApi = async (data) => {
  return await axiosInstance.post(endpoint.detectionFail, data).then((response) => response.data);
};

/*얼굴인식 동영상 업로드 API*/
export const uploadFaceVideoApi = async (data, reqHeader) => {
  return await multiPartInstance
    .post(endpoint.uploadFaceVideo, data, { headers: reqHeader })
    .then((response) => response.data);
};

/*웹뷰 로그 적재 API*/
export const webViewLogsApi = async (data) => {
  return await axiosInstance.post(endpoint.webViewLogs, data).then((response) => response.data);
};
