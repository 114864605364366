// style
import styled from '@emotion/styled';
import closeIcon from 'src/assets/images/close.svg';

const ActionIcon = styled.div`
  width: 28px;
  height: 28px;
`;
const Titlearea = styled.div`
  flex: 1;
  height: 27px;
`;

const Container1 = styled.div`
  align-self: stretch;
  flex: 1;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--spacing-150) var(--spacing-250);
  gap: var(--spacing-150);
`;

const HeaderRoot = styled.div`
  width: 100%;
  position: relative;
  height: 52px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

function ModalHeader({ close }) {
  return (
    <HeaderRoot>
      <Container1>
        <ActionIcon />
        <Titlearea />
        <ActionIcon />
        <ActionIcon onClick={close}>
          <img src={closeIcon} alt="Close" />
        </ActionIcon>
      </Container1>
    </HeaderRoot>
  );
}

export default ModalHeader;
