import { recoilPersist } from 'recoil-persist';

export const { persistAtom: deviceInfoPersist } = recoilPersist({
  key: 'deviceInfoState',
  storage: sessionStorage
});

export const { persistAtom: channelInfoPersist } = recoilPersist({
  key: 'channelInfoState',
  storage: sessionStorage
});

export const { persistAtom: ocrFaceInfoPersist } = recoilPersist({
  key: 'ocrFaceInfoState',
  storage: sessionStorage
});
