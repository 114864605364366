import { atom } from 'recoil';
import { channelInfoPersist, deviceInfoPersist, ocrFaceInfoPersist } from 'src/recoil/recoil-persist';

/*접속 디바이스 정보*/
export const deviceInfoState = atom({
  key: 'deviceInfoState',
  default: {
    os: '',
    device: '',
    iosVersion: 0
  },
  effects_UNSTABLE: [deviceInfoPersist]
});

/*접속 채널 정보*/
export const channelInfoState = atom({
  key: 'channelInfoState',
  default: {
    channel: '',
    chnlRedrctAddr: '',
    ocrUseYn: 'Y',
    faceUseYn: 'N',
    faceStopYn: 'N',
    calledBy: '',
    prevRivsRqstId: '',
    faceMode: 'D'
  },
  effects_UNSTABLE: [channelInfoPersist]
});

/*OCR 결과 데이터*/
export const ocrState = atom({
  key: 'ocrState',
  default: {}
});

/*안면인식에 필요한 OCR 결과 정보*/
export const ocrFaceInfoState = atom({
  key: 'ocrFaceInfoState',
  default: {
    cardType: '',
    photoIdImageFileNm: ''
  },
  effects_UNSTABLE: [ocrFaceInfoPersist]
});
