import { useBlocker } from 'react-router-dom';
import Dialog from 'src/components/Dialog';
import { useState } from 'react';
import { useClose } from 'src/hooks/use-close';

/**
 * 뒤로가기 시 종료 팝업 제어 헨들러
 * 홈 패스와 안면인식 시작 페이지에서 뒤로가기 시 종료 모달 open
 * */
export default function BackHandler({ children }) {
  const [openExitModal, setOpenExitModal] = useState(false);
  const { webClose } = useClose();

  useBlocker(({ currentLocation, nextLocation, historyAction }) => {
    if (currentLocation.pathname !== nextLocation.pathname && historyAction === 'POP') {
      setOpenExitModal(true);

      return true;
    }
  });

  /*종료확인모달 - 확인 클릭이벤트*/
  const handleExitConfirm = () => {
    setOpenExitModal(false);
    webClose();
  };

  /*종료확인모달 - 취소 클릭이벤트*/
  const handleExitCancel = () => {
    setOpenExitModal(false);
  };

  return (
    <>
      {children}
      {openExitModal && (
        <Dialog
          title="인증 종료"
          description={'비대면 인증 서비스가 완료되지 않았습니다.\n인증서비스를 종료합니다.'}
          buttonContents={[
            {
              text: '취소',
              fill: 'neutral1',
              onClick: handleExitCancel
            },
            {
              text: '확인',
              fill: 'primary',
              onClick: handleExitConfirm
            }
          ]}
        />
      )}
    </>
  );
}
