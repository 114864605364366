import { selector } from 'recoil';
import { deviceInfoState } from 'src/recoil/recoil';
import { DEVICE_TYPE } from 'src/constants/rivConstant';

/**
 * 접속 환경이 모바일인지 PC 인지 판단하는 selector
 * @return 'MOBILE' 일 경우 true, 그 외 false
 * */
export const isMobileSelector = selector({
  key: 'isMobileSelector',
  get: ({ get }) => {
    const deviceInfo = get(deviceInfoState);
    if (deviceInfo.device === DEVICE_TYPE.MOBILE) {
      return true;
    }

    return false;
  }
});

/**
 * Swift WKWebView 를 통해 접속시 ios 15 버전 이전 버전에서는 WebRTC 지원 불가임으로
 * 네이티브 카메라 호출이 필요한지 여부 확인 selector
 * @return ios 15 버전 미만일 경우 true, 이상이면 false
 * */
export const useNativeCameraSelector = selector({
  key: 'useNativeCameraSelector',
  get: ({ get }) => {
    const deviceInfo = get(deviceInfoState);
    if (deviceInfo.iosVersion && deviceInfo.iosVersion < 15) {
      return true;
    }

    return false;
  }
});
