// react
import { useEffect, useRef, useState } from 'react';

// recoil
import { isMobileSelector } from 'src/recoil/selector';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { channelInfoState, ocrFaceInfoState, ocrState } from 'src/recoil/recoil';

// libraries
import { useNavigate } from 'react-router-dom';

// apis
import { doRmtIdVerApi } from 'src/apis/apis';

// hooks
import { useClose } from 'src/hooks/use-close';
import { useCalledBy } from 'src/hooks/use-called-by';

// scripts
import { aes256Decrypt } from 'src/utils/crypto';
import { formatDate } from 'src/utils/commonUtils';

// constants
import { paths } from 'src/routes/path';
import { RivConstant } from 'src/constants/rivConstant';

// components
import Button from 'src/components/Button';
import Header from 'src/components/Header';
import Dialog from 'src/components/Dialog';
import Loading from 'src/components/Loading';
import FieldSet from 'src/components/FieldSet';
import TextTitle from 'src/components/TextTitle';
import Copyright from 'src/components/Copyright';
import ButtonGroup from 'src/components/ButtonGroup';

// style
import styled from '@emotion/styled';
import styles from 'src/assets/app.module.css';

const FieldSetBox = styled.div``;

export default function Step5() {
  // navigate
  const navigate = useNavigate();
  // recoil
  const resultData = useRecoilValue(ocrState); // OCR 추출 데이터
  const isMobile = useRecoilValue(isMobileSelector); // 모바일 환경 flag
  const channelInfo = useRecoilValue(channelInfoState); // 접속 채널 정보
  const setOcrFaceInfo = useSetRecoilState(ocrFaceInfoState); // 안면인식에서 사용 할 OCR 정보
  // state
  const [isLoading, setIsLoading] = useState(false); // 로딩 스피너
  const [openModal, setOpenModal] = useState(false); // 에러모달
  const [modalTitle, setModalTitle] = useState('에러 발생'); // 에러 제목
  const [modalMessage, setModalMessage] = useState('에러가 발생하였습니다. 잠시 후에 다시 시도해 주세요.'); // 에러 메시지
  const [openCountError, setOpenCountError] = useState(false); // 인증 시도 횟수 초과 모달
  const [openConnectionError, setOpenConnectionError] = useState(false); // 통신에러 모달
  // ref
  const isDisableAPI = useRef(false); // API 진행 FLAG
  // hook
  const { cleanData, webClose } = useClose();
  const { tmmPageSend } = useCalledBy();
  /**-------------------------------- useEffect --------------------------------------*/
  useEffect(() => {
    // TMM 채널 페이지 이동 메시지 전달
    tmmPageSend(5);

    /*OCR 결과 데이터 검사*/
    if (Object.keys(resultData).length < 1) {
      navigate(paths.step3, { replace: true });
    }

    const handleMoveBack = () => {
      cleanData(false);
      navigate(paths.step3, { replace: true });
    };
    window.addEventListener('popstate', handleMoveBack);

    return () => {
      window.removeEventListener('popstate', handleMoveBack);
    };
  }, []);

  /**-------------------------------- apis --------------------------------------*/
  /*신분증 진위확인 API 호출*/
  const handleComplete = async () => {
    if (isDisableAPI.current) {
      return;
    }
    setIsLoading(true);
    isDisableAPI.current = true;
    const data = {
      name: resultData.name, // 신분증성명
      cardType: resultData.cardType, // 신분증 구분
      faceExResult: resultData.faceExResult, // 사진특징점평가점수
      faceExFeature: resultData.faceExFeature, // 얼굴 특징점
      idNumberEnc: resultData.idNumberEnc, // 주민번호 암호화
      issueDateEnc: resultData.issueDateEnc, // 발급일자 암호화
      driverLicenseNumberEnc: resultData.driverLicenseNumberEnc, // 면허증번호 암호화
      faceDvsnCode: isMobile ? '10' : '11', // 대면 비대면 구분코드(모바일: 10, 그 외: 11)
      originIdImageFileNm: resultData.originIdImageFileNm, // 신분증 원본 이미지 파일명
      photoIdImageFileNm: resultData.photoIdImageFileNm, // 얼굴 크롭 이미지 파일명
      rivsRqstId: sessionStorage.getItem(RivConstant.REQUEST_ID) // 비대면인증시스템요청ID
    };

    try {
      // 신분증 진위확인 API 호출
      let response = await doRmtIdVerApi(data);
      console.log('===== [handleComplete] ===== response: ', response);
      console.log('===== [handleComplete] ===== channelInfo: ', channelInfo);

      if (response.header.rsltCode === '0510' || response.header.rsltCode === '0511') {
        // 인증 횟수 초과 에러
        setOpenCountError(true);
      } else if (response.header.rsltCode !== '0000') {
        console.error('===== [신분증 진위확인 API 에러] ===== response.header', response.header);
        setModalTitle(response.header.rsltMsgeTitl);
        setModalMessage(response.header.rsltMsgeCntn);
        setOpenModal(true);
      } else if (response.header.rsltCode === '0000' && channelInfo.faceUseYn === 'Y') {
        // 안면인식 진행
        setOcrFaceInfo({
          cardType: resultData.cardType,
          photoIdImageFileNm: resultData.photoIdImageFileNm
        });
        navigate(paths.liveInfo, { replace: true });
      } else if (response.header.rsltCode === '0000' && channelInfo.faceUseYn === 'N') {
        // 완료 페이지 진행
        cleanData(false);
        navigate(paths.step6, { replace: true });
      }
      response = null;
    } catch (error) {
      console.error('===== [doRmtIdVer 통신에러] ===== error: ', error);
      setOpenConnectionError(true);
    } finally {
      setIsLoading(false);
      isDisableAPI.current = false;
    }
  };

  /**-------------------------------- 이벤트헨들러 --------------------------------------*/
  /*다시촬영 클릭이벤트*/
  const handleRetake = () => {
    cleanData(false);
    navigate(paths.step3, { replace: true });
  };

  /*에러모달 - 홈으로 클릭이벤트*/
  const goHome = () => {
    cleanData(false);
    setOpenModal(false);
    navigate(-3);
  };

  /*인증횟수 초과 에러모달 - 확인 클릭이벤트*/
  const handleExitClick = () => {
    setOpenCountError(false);
    webClose();
  };

  /*통신에러모달 - 확인 클릭이벤트*/
  const handleConnectionExitClick = () => {
    setOpenConnectionError(false);
    webClose();
  };

  return (
    <>
      <div className={styles.cont_box}>
        <Header step="75%" />

        <div className={styles.cont_body}>
          <div className={`${styles.ContWrap} ${styles.ContWrap_block}`}>
            <TextTitle
              size="xlarge"
              type="title"
              title={`신분증이 정상적으로 \n촬영되었나요?`}
              description={`신분증 사진 및 인식된 정보가 틀리면 \n이용이 제한되니 반드시 정보를 확인해주세요`}
            />

            {/*신분증 마스킹 이미지 사진 영역 (금보원 심사로인해 현재 주석 처리)*/}
            {/*<ImgBox id="resultCanvasWrapper" className="result-canvas-wrap">
              <canvas id="resultCanvas" className="result-canvas"></canvas>
            </ImgBox>*/}

            <FieldSetBox>
              <FieldSet label="이름" value={resultData?.name} type="text" placeholder="" />

              {resultData?.maskedIdNumberEnc && (
                <FieldSet
                  label="주민등록 번호"
                  value={aes256Decrypt(resultData?.maskedIdNumberEnc).substring(0, 6)}
                  value2={aes256Decrypt(resultData?.maskedIdNumberEnc).substring(
                    6,
                    aes256Decrypt(resultData?.maskedIdNumberEnc).length
                  )}
                  type="text"
                  multi="true"
                  placeholder=""
                />
              )}

              {resultData?.maskedDriverNumberEnc && (
                <FieldSet
                  label="면허번호"
                  value={aes256Decrypt(resultData?.maskedDriverNumberEnc)}
                  type="text"
                  placeholder=""
                />
              )}

              <FieldSet
                label="발급일자"
                value={formatDate(aes256Decrypt(resultData?.issueDateEnc))}
                type="text"
                placeholder=""
              />
            </FieldSetBox>
          </div>
          <div className={styles.btm_box}>
            <ButtonGroup gap="true" main>
              <Button text="다시 촬영" fill="neutral1" size="large" status="active" onClick={handleRetake} />
              <Button text="완료" fill="primary" size="large" status="active" onClick={handleComplete} />
            </ButtonGroup>
            <Copyright />
          </div>
        </div>
      </div>

      {isLoading && <Loading description={'잠시만 기다려 주세요\n진위여부 판별 중 입니다.'} />}
      {openModal && (
        <Dialog
          title={modalTitle}
          description={modalMessage}
          buttonContents={[
            {
              text: '홈으로',
              fill: 'neutral1',
              onClick: goHome
            },
            {
              text: '재촬영',
              fill: 'primary',
              onClick: handleRetake
            }
          ]}
        />
      )}
      {openConnectionError && (
        <Dialog
          title={'통신 에러 발생'}
          description={'통신 에러가 발생하였습니다.\n잠시 후에 다시 시도해 주세요.'}
          buttonContents={[
            {
              text: '확인',
              fill: 'primary',
              onClick: handleConnectionExitClick
            }
          ]}
        />
      )}
      {openCountError && (
        <Dialog
          title={'인증 횟수 초과'}
          description={'인증 시도 가능 횟수가 초과되었습니다. 시도를 중단하고 잠시 후에 다시 시도해 주세요.'}
          buttonContents={[
            {
              text: '확인',
              fill: 'primary',
              onClick: handleExitClick
            }
          ]}
        />
      )}
    </>
  );
}
