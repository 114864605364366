// react
import React, { useEffect } from 'react';

// components
import Copyright from 'src/components/Copyright';

// style
import styled from '@emotion/styled';
import styles from 'src/assets/app.module.css';
import Button from 'src/components/Button';
import ButtonGroup from 'src/components/ButtonGroup';
import Complete_img from 'src/assets/images/ico_complete.svg';
import { useClose } from 'src/hooks/use-close';
import { useCalledBy } from 'src/hooks/use-called-by';
import { CARD_TYPE, RESULT } from 'src/constants/rivConstant';
import { useRecoilValue } from 'recoil';
import { ocrFaceInfoState } from 'src/recoil/recoil';

const MsgBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  font-size: var(--body2-size);
  line-height: 150%;
  font-family: var(--body2);
  color: var(--text-subdued);
  padding: 0 24px;

  & p {
    font-size: var(--title2-size);
    line-height: 150%;
    font-family: var(--subtitle2-family);
    color: var(--system-black);
    text-align: center;
    display: block;
    font-weight: 700;
    margin-bottom: 8px;
  }

  & span {
    font-size: var(--body1-size);
    line-height: 150%;
    font-family: var(--body1-family);
    color: var(--system-sceondary-gray-800);
    text-align: center;
    display: block;
  }
`;

export default function Step6() {
  // hook
  const { webClose } = useClose();
  const { tmmPageSend } = useCalledBy();
  const ocrFaceInfo = useRecoilValue(ocrFaceInfoState);

  useEffect(() => {
    // TMM 채널 페이지 이동 메시지 전달
    tmmPageSend(9);
  }, []);

  /**-------------------------------- 이벤트헨들러 --------------------------------------*/
  // 서비스 홈 이동 버튼 클릭이벤트
  const handleGoBackClick = () => {
    webClose(ocrFaceInfo.cardType === CARD_TYPE.JUMIN ? RESULT.SUCCESS_JUMIN : RESULT.SUCCESS_DRIVER);
  };

  return (
    <>
      <div className={styles.cont_box}>
        <div className={styles.cont_body}>
          <div className={styles.ContWrap}>
            <MsgBox>
              <img src={Complete_img} className={styles.info_img} alt="React" />
              <p>인증이 완료되었습니다.</p>

              <span>
                사용 중인 서비스로 돌아가
                <br />
                계속 진행해 주세요
              </span>
            </MsgBox>
          </div>
          <div className={styles.btm_box}>
            <ButtonGroup gap="true" main>
              <Button text="서비스 홈 이동" fill="primary" size="large" status="active" onClick={handleGoBackClick} />
            </ButtonGroup>
            <Copyright />
          </div>
        </div>
      </div>
    </>
  );
}
