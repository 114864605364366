import axios from 'axios';
import { RivConstant } from 'src/constants/rivConstant';

/*인터셉터 설정*/
function setupInterceptors(instance) {
  /**-------------------------------- 요청 인터셉터 --------------------------------------*/
  instance.interceptors.request.use((config) => {
    const accessToken = sessionStorage.getItem(RivConstant.ACCESS_TOKEN_KEY);
    if (accessToken) {
      config.headers = {
        ...config.headers,
        'Riv-Access-Token': accessToken
      };
    }

    return config;
  });

  /**-------------------------------- 응답 인터셉터 --------------------------------------*/
  instance.interceptors.response.use((response) => {
    if (Object.prototype.hasOwnProperty.call(response.data.header, 'rsltCode')) {
      const rsltCode = response.data.header['rsltCode'];
      switch (rsltCode) {
        case '0051': // 접근, 갱신 토큰이 존재하지 않습니다.
        case '0052': // 접근, 갱신 토큰이 모두 만료 되었습니다.
        case '0054': // 유효하지 않은 접근 토큰
        case '0055': // 토큰 갱신 가능 시간 마료
        case '0056': // 토큰 검증 오류
          /* 토큰 검증 오류시 첫 페이지로 redirect. 세션 스토리지와 query string 에도 토큰이 없기 떄문에
           * 첫 페이지에서 토큰 에러 모달 오픈 후 Exit 처리
           * */
          sessionStorage.removeItem(RivConstant.ACCESS_TOKEN_KEY);
          sessionStorage.removeItem(RivConstant.REQUEST_ID);
          window.location.href = `${window.location.origin}/`;
        default:
          return response;
      }
    }

    return response;
  });
}

/*Axios 인스턴스 생성*/
function createAxiosInstance(config) {
  const instance = axios.create(config);
  // 인터셉터 설정
  setupInterceptors(instance);

  return instance;
}

export default createAxiosInstance;
