// react
import { useEffect, useRef, useState } from 'react';

// recoil
import { useRecoilState, useRecoilValue } from 'recoil';
import { channelInfoState, deviceInfoState, ocrState } from 'src/recoil/recoil';
import { useNativeCameraSelector } from 'src/recoil/selector';

// libraries
import { useNavigate } from 'react-router-dom';

// hooks
import { useClose } from 'src/hooks/use-close';
import { useCalledBy } from 'src/hooks/use-called-by';

// scripts
import { NativeBridge } from 'src/utils/nativeBridge';
import { contentsLoaded, scanner, sendImage2Server } from 'src/scripts/robiscan';

// constants
import { paths } from 'src/routes/path';

// components
import Dialog from 'src/components/Dialog';
import Loading from 'src/components/Loading';

// style
import styled from '@emotion/styled';
import Mask_bg from 'src/assets/images/mask_bg.svg';
import Mask_img from 'src/assets/images/mask_canvas.svg';
import cameraBtn from 'src/assets/images/btn-camera.svg';
import Step3a_img from 'src/assets/images/img-step3a.svg';
import Step3b_img from 'src/assets/images/img-step3b.svg';
import { CHANNEL_CODE, OS_TYPE } from 'src/constants/rivConstant';

const BgRoot = styled.div`
  position: relative;
  height: 100%;
  height: 100dvh;
  background-color: rgba(0 0 0 / 0.9);
`;

const SubBox = styled.div`
  position: relative;
  height: 100%;
  height: 100dvh;

  @media (min-width: 590px) and (max-width: 720px) and (min-height: 550px) and (min-aspect-ratio: 0.982/1) {
    width: 55%;
    transform: translate(-50%, 0) rotate(0);
    transform-origin: top left;
    top: 0;
    left: 50%;
    height: 100%;
  }
  @media (min-width: 780px) and (max-width: 850px) and (min-aspect-ratio: 0.982/1) {
    width: 43%;
    transform: translate(-50%, 0) rotate(0);
    transform-origin: top left;
    top: 0;
    left: 50%;
    height: 100%;
  }
  @media (min-aspect-ratio: 2/1) and (orientation: landscape) {
    transform: rotate(-90deg);
    transform-origin: top left;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100dvh;
    height: 100dvw;
  }
`;

const P = styled.p`
  margin: 0;
`;

const TextTitle = styled.div`
  position: relative;
  z-index: 100;
  font-size: var(--subtitle2-size);
  line-height: 150%;
  font-family: var(--subtitle2-family);
  color: #fff;
  text-align: left;
  display: inline-block;
  max-width: 300px;
  margin: 84px 20px 0;
  @media screen and (max-height: 580px) {
    margin: 60px 20px 0;
  }
`;

const CameraVideo = styled.video`
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  mask-image: url(${Mask_bg});
  mask-repeat: no-repeat;
  mask-position: center center;
  mask-size: 100%;
  visibility: hidden;
`;

const CameraCanvas = styled.canvas`
  width: 100%;
  height: 100%;
  padding: 10px;
  object-fit: cover;
`;

const ButtonCameraIcon = styled.img`
  width: 100%;
  position: absolute;
  left: 50%;
  bottom: 28px;
  transform: translateX(-50%);
  max-width: 100%;
  overflow: hidden;
  width: 56px;
  height: 56px;
  cursor: pointer;
  z-index: 5;

  @media (min-height: 500px) and (max-height: 580px) {
    bottom: 15px;
  }
  @media screen and (max-height: 500px) {
    bottom: 10px;
  }
`;

const OcrResult = styled.section`
  display: none;
`;

const OcrReadFile = styled.input`
  display: none;
`;

const OcrReadReault = styled.div`
  display: none;
`;

const Maskimg = styled.div`
  position: relative;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: #fff;
    mask-image: url(${Mask_img});
    mask-repeat: no-repeat;
    mask-position: center center;
    mask-size: 89.8%;
    aspect-ratio: 1;
    object-fit: cover;
  }
`;

const CameraInfo = styled.div`
  position: absolute;
  bottom: 102px;
  display: flex;
  padding: 0 20px;
  gap: 4px;
  width: 100%;
  justify-content: center;

  & item {
    position: relative;
    font-size: var(--caption-size);
    line-height: 140%;
    font-family: var(--caption-family);
    color: var(--text-neutral-02);
    text-align: center;
    display: inline-block;
    padding: 0 20px;
    word-break: keep-all;

    & img {
      display: block;
      margin: 0 auto 8px;
      width: 44px;
    }
  }

  @media (min-height: 500px) and (max-height: 580px) {
    bottom: 83px;
    & item {
      line-height: 120%;
      & img {
        margin: 0 auto 0;
      }
    }
  }
  @media screen and (max-height: 500px) {
    bottom: 78px;
    & item {
      line-height: 117%;
      & img {
        margin: 0 auto 0;
      }
    }
  }
`;

export default function Step3() {
  // navigate
  const navigate = useNavigate();
  // recoil
  const [resultData, setResultData] = useRecoilState(ocrState); // OCR 추출 데이터
  const useNativeCamera = useRecoilValue(useNativeCameraSelector); // 네이티브 카메라 모듈 사용여부 selector
  const channelInfo = useRecoilValue(channelInfoState); // 접속 채널 정보
  const deviceInfo = useRecoilValue(deviceInfoState); // 접속 디바이스 정보
  // states
  const [openModal, setOpenModal] = useState(false); // 에러모달 (확인, 재촬영 버튼)
  const [modalTitle, setModalTitle] = useState('에러 발생'); // 에러 제목
  const [modalMessage, setModalMessage] = useState('에러가 발생하였습니다.\n잠시 후에 다시 시도해 주세요.'); // 에러 메시지
  const [isLoading, setIsLoading] = useState(true); // 로딩 스피너
  const [loadingMessage, setLoadingMessage] = useState('잠시만 기다려 주세요.\n카메라를 여는 중 입니다.'); // 로딩 메시지
  const [openCameraModal, setOpenCameraModal] = useState(false); // 카메라 권한 에러 모달
  // ref
  const childrenRef = useRef(null);
  // hook
  const { cleanData, webClose } = useClose();
  const { tmmPageSend } = useCalledBy();

  /**-------------------------------- OCR 통신 contentsLoaded script --------------------------------------*/
  /*카메라 모듈 초기화 성공 핸들러*/
  const handleSuccess = () => {
    setIsLoading(false);
    document.getElementById('scanView').style.visibility = 'visible';
  };

  /*한화생명앱 카메라 권한 요청 브릿지통신 콜백 핸들러*/
  window.cameraAuthCallback = (response) => {
    console.log('===== [cameraAuthCallback 호출] =====');
    if (response) {
      const jsonResponse = decodeURIComponent(response);
      const parsedResponse = JSON.parse(jsonResponse);
      const parsedData = JSON.parse(parsedResponse.resData);
      console.log('===== [cameraAuthCallback] ====== parsedResponse: ', parsedResponse);
      console.log('===== [cameraAuthCallback] ====== parsedData: ', parsedData);
      scanner?.callbacks.reShoot();
    }
  };

  /*카메라 모듈 초기화 실패 핸들러*/
  const handleFailure = (err) => {
    setIsLoading(false);
    const message = err.toString();

    if (message.includes('NotAllowedError')) {
      // 권한 거부 에러
      if (channelInfo.channel === CHANNEL_CODE.HCI && deviceInfo.os === OS_TYPE.ANDROID) {
        // 한화생명 앱에서 안드로이드 접속 할 경우 권한 요청을 위해 브릿지 통신 사용함.
        const args = {
          permissions: ['android.permission.CAMERA', 'android.permission.RECORD_AUDIO'],
          title: '카메라 권한 요청',
          content:
            '비대면 인증을 위해 신분증과 얼굴을 촬영해야\n합니다.\n카메라 이용을 위해 휴대폰 설정 > 어플리케이션에서 해당 앱의 권한을 허용해 주세요.'
        };
        NativeBridge.call('wallet', 'requestPermission', args, 'cameraAuthCallback');
      } else {
        // 권한 에러는 openModal 이 아닌 openCameraModal 모달을 사용.
        setOpenCameraModal(true);
      }

      return;
    } else if (message.includes('NotFoundError')) {
      // 카메라 디바이스 not found 에러
      setModalTitle('카메라 모듈 초기화 실패');
      setModalMessage(`카메라 디바이스를 찾을 수 없습니다.\n카메라가 준비되어있는지 확인해 주세요.`);
    } else if (message.includes('OcrCertError')) {
      // OCR 토큰 만료 에러
      setModalTitle('카메라 모듈 초기화 실패');
      setModalMessage(`카메라 모듈 토큰 인증에러. 다시 시도해 주세요.`);
    } else if (message.includes('TokenError')) {
      // OCR 인증 토큰 발급에러 (getKey, getToken api 에러)
      setModalTitle('카메라 모듈 초기화 실패');
      setModalMessage(`카메라 모듈 토큰 발급 에러. 다시 시도해 주세요.`);
    } else {
      setModalTitle('카메라 모듈 초기화 실패');
      setModalMessage(message);
    }
    setOpenModal(true);
  };

  /*OCR 통신 중 Loading 스피너 핸들러*/
  const handleOnLoading = (loading) => {
    setLoadingMessage('잠시만 기다려 주세요.\n신분증 정보를 확인 중 입니다.');
    setIsLoading(loading);
  };

  /*포지큐브 카메라 초기화*/
  const initializeCamera = () => {
    /*IOS 14.3 이전 버전의 경우 한화생명 앱에서 가동시 WebRTC 호환이 안되어 한화생명 앱 카메라 모듈 사용*/
    if (useNativeCamera && window.webkit && window.webkit.messageHandlers.callNative) {
      setIsLoading(false);
      // 포지큐브 OCR 라이브러리 초기화
      contentsLoaded(handleSuccess, handleFailure, handleOnLoading, true);
      // 한화생명 앱 네티이브 카메라 모듈 호출
      NativeBridge.call('wallet', 'getPictureBinary', {}, 'callbackNativeResponse');
    } else {
      // 포지큐브 OCR 라이브러리 초기화
      contentsLoaded(handleSuccess, handleFailure, handleOnLoading, false);
    }
  };

  /**-------------------------------- useEffect --------------------------------------*/
  useEffect(() => {
    // TMM 채널 페이지 이동 메시지 전달
    tmmPageSend(3);
    // 포지큐브 카메라 초기화
    initializeCamera();

    return () => {
      scanner?.callbacks.pageClose();
    };
  }, []);

  /*OCR 결과 처리*/
  useEffect(() => {
    let current;

    const observer = new MutationObserver(() => {
      const getOcrData = document.querySelector('#ocrResultData').dataset.result || 'init';
      const getOcrResultData =
        document.querySelector('#ocrResultData').dataset.resultHeader !== ''
          ? JSON.parse(document.querySelector('#ocrResultData').dataset.resultHeader)
          : 'init';

      if (
        getOcrData &&
        getOcrData.trim() !== '' &&
        getOcrData.trim() !== 'null' &&
        getOcrData.trim() !== 'init' &&
        getOcrData.trim() !== 'reset'
      ) {
        setResultData(JSON.parse(getOcrData));
        document.querySelector('#ocrResultData').dataset.result = 'reset';
      }

      if (
        getOcrData.trim() === '' ||
        !getOcrData ||
        (getOcrData.trim() !== 'init' && getOcrResultData.rsltCode === '9999' && getOcrData.trim() !== 'reset')
      ) {
        setModalTitle('에러 발생');
        setModalMessage('OCR 추출중 에러가 발생하였습니다. 다시 시도해 주세요.');
        setOpenModal(true);
      } else if (
        getOcrData.trim() === '' ||
        !getOcrData ||
        (getOcrData.trim() !== 'init' && getOcrResultData.rsltCode !== '0000' && getOcrData.trim() !== 'reset')
      ) {
        setModalTitle(getOcrResultData.rsltMsgeTitl);
        setModalMessage(getOcrResultData.rsltMsgeCntn);
        setOpenModal(true);
      }
    });

    if (childrenRef.current) {
      observer.observe(childrenRef.current, {
        childList: true,
        attributes: true,
        subtree: true
      });
      current = childrenRef.current;
    }

    return () => {
      if (current) {
        observer.disconnect(current);
      }
    };
  }, [resultData]);

  /*OCR 결과 민증 / 운전면허증에 따라 step4 또는 step5로 navigate 처리*/
  useEffect(() => {
    const getOcrResultData =
      document.querySelector('#ocrResultData').dataset.resultHeader &&
      document.querySelector('#ocrResultData').dataset.resultHeader !== ''
        ? JSON.parse(document.querySelector('#ocrResultData').dataset.resultHeader)
        : 'init';

    if (getOcrResultData === 'init') {
      return;
    }

    const isAbleNext = getOcrResultData.rsltCode === '0000';

    if (resultData && Object.keys(resultData).length > 0) {
      if (resultData.cardType === '1' && !openModal && isAbleNext) {
        document.querySelector('#ocrResultData').dataset.result = '';
        document.querySelector('#ocrResultData').dataset.resultHeader = '';
        navigate(paths.step4);
      } else if (resultData.cardType === '2' && !openModal && isAbleNext) {
        document.querySelector('#ocrResultData').dataset.result = '';
        document.querySelector('#ocrResultData').dataset.resultHeader = '';
        navigate(paths.step5);
      }
    }
  }, [resultData]);

  /**-------------------------------- 이벤트 핸들러 --------------------------------------*/
  /*모달 - 재촬영 클릭이벤트*/
  const handleRetakeClick = () => {
    setOpenCameraModal(false);
    setOpenModal(false);
    if (useNativeCamera && window.webkit && window.webkit.messageHandlers.callNative) {
      NativeBridge.call('wallet', 'getPictureBinary', {}, 'callbackNativeResponse');
    } else {
      setLoadingMessage('잠시만 기다려 주세요.\n카메라를 여는 중 입니다.');
      setIsLoading(true);
      scanner?.callbacks.reShoot();
    }
  };

  /*모달 - 홈으로 클릭이벤트*/
  const handleGoHomeClick = () => {
    cleanData(false);

    setOpenModal(false);
    navigate(-2);
  };

  /*카메라 권한 에러모달 - 종료 클릭이벤트*/
  const handleCameraExitClick = () => {
    setOpenCameraModal(false);
    webClose();
  };

  /**-------------------------------- scripts --------------------------------------*/
  /*한화생명 앱 카메라 모듈에서 사진 촬영 후 base64 사진을 받는 callback 함수*/
  window.callbackNativeResponse = (response) => {
    console.log('[callbackNativeResponse] 호출');
    if (response) {
      const jsonResponse = decodeURIComponent(response);
      const parsedResponse = JSON.parse(jsonResponse);
      const parsedData = JSON.parse(parsedResponse.resData);
      const base64Image = parsedData.fileData[0];

      if (base64Image === 'CANCLE') {
        NativeBridge.call('wallet', 'webClose', {}, 'callbackNativeResponse');
      } else {
        // OCR 추출 API 모듈 호출
        sendImage2Server(base64Image);
      }
    } else {
      setModalTitle('이미지 에러 발생');
      setModalMessage('이미지가 없습니다.');
      setOpenModal(true);
    }
  };

  return (
    <>
      <BgRoot id="scanContainer" className="scan-container">
        <SubBox>
          <Maskimg>
            <CameraVideo id="scanView" playsInline muted className="scan-view" />
            <div id="scanBoxMask" className="" />
            <div id="scanBoxGuide" className="">
              <div id="scanBoxLoading" className="" />
              <div id="scanBoxPassportPhoto" className="" />
              <div id="scanBoxPassportMrz" className="" />
              <TextTitle id="scanBoxDesc">
                <P>표시된 영역에 신분증을 맞춰 배치 후</P>
                <P>하단 버튼을 누르면 촬영됩니다.</P>
              </TextTitle>
            </div>
            <CameraInfo>
              <item>
                <img src={Step3a_img} alt="주민등록증 샘플 이미지" />
                어두운 바닥에서 촬영해 주세요
              </item>
              <item>
                <img src={Step3b_img} alt="주민등록증 샘플 이미지" />
                빛이 반사되지 않도록 주의해주세요
              </item>
            </CameraInfo>
            <button id="autoCameraToggle" className="" style={{ display: 'none' }}></button>

            {/* <CameraBox className="scan-box-wrap"> */}
            <CameraCanvas id="scanBoxMask" style={{ display: 'none' }} />
            {/* </CameraBox> */}

            <div id="camera-btn_layout">
              <ButtonCameraIcon id="takeCameraBtn" className="camera-btn__take" src={cameraBtn} alt="Camera Action" />
            </div>
            <OcrReadFile id="file-capture-manual" type="file" name="files" capture="camera" accept="image/jpeg" />
          </Maskimg>
        </SubBox>
      </BgRoot>
      <OcrResult id="resultContainer" className="">
        <p id="resultBoxDesc" className=""></p>
        <canvas id="resultCanvas" className=""></canvas>
        <button id="resetBtn" className=""></button>
        <button id="redetectBtn" className=""></button>
        <button id="sendServerBtn" className=""></button>
      </OcrResult>
      <OcrReadReault id="ocrResultData" ref={childrenRef}></OcrReadReault>
      {isLoading && <Loading description={loadingMessage} />}
      {openModal && (
        <Dialog
          title={modalTitle}
          description={modalMessage}
          buttonContents={[
            {
              text: '홈으로',
              fill: 'neutral1',
              onClick: handleGoHomeClick
            },
            {
              text: '재촬영',
              fill: 'primary',
              onClick: handleRetakeClick
            }
          ]}
        />
      )}
      {openCameraModal && (
        <Dialog
          title={'카메라 권한 요청'}
          description={
            '비대면 인증을 위해 신분증과 얼굴을 촬영해야\n합니다.\n카메라 이용을 위해 휴대폰 설정 > 어플리케이션에서 해당 앱의 권한을 허용해 주세요.'
          }
          buttonContents={[
            {
              text: '종료',
              fill: 'neutral1',
              onClick: handleCameraExitClick
            },
            {
              text: '확인',
              fill: 'primary',
              onClick: handleRetakeClick
            }
          ]}
        />
      )}
    </>
  );
}
