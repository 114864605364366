import { paths } from 'src/routes/path';
import MainLayout from 'src/layouts/main-layout';
import { createBrowserRouter } from 'react-router-dom';

// HOC
import TokenGuard from 'src/hocs/token-guard';
import BackHandler from 'src/hocs/back-handler';

// 서비스메인
import Step1 from 'src/pages/step1';
import Step2 from 'src/pages/step2';
import Step3 from 'src/pages/step3';
import Step4 from 'src/pages/step4';
import Step5 from 'src/pages/step5';
import Step6 from 'src/pages/step6';
import LivenessInfo from 'src/pages/liveness-info';
import LivenessTip from 'src/pages/liveness-tip';
import Liveness from 'src/pages/liveness';
import Page404 from 'src/pages/page404';

export const router = createBrowserRouter([
  // ----------------------------------------------------------------------
  // 서비스메인
  {
    path: paths.home,
    element: (
      <BackHandler>
        <MainLayout>
          <Step1 />
        </MainLayout>
      </BackHandler>
    )
  },
  // step2
  {
    path: paths.step2,
    element: (
      <TokenGuard>
        <MainLayout>
          <Step2 />
        </MainLayout>
      </TokenGuard>
    )
  },
  // step3
  {
    path: paths.step3,
    element: (
      <TokenGuard>
        <MainLayout>
          <Step3 />
        </MainLayout>
      </TokenGuard>
    )
  },
  // step4
  {
    path: paths.step4,
    element: (
      <TokenGuard>
        <MainLayout>
          <Step4 />
        </MainLayout>
      </TokenGuard>
    )
  },
  // step5
  {
    path: paths.step5,
    element: (
      <TokenGuard>
        <MainLayout>
          <Step5 />
        </MainLayout>
      </TokenGuard>
    )
  },
  // step6
  {
    path: paths.step6,
    element: (
      <BackHandler>
        <MainLayout>
          <Step6 />
        </MainLayout>
      </BackHandler>
    )
  },
  // liveness 안내 페이지
  {
    path: paths.liveInfo,
    element: (
      <BackHandler>
        <TokenGuard>
          <MainLayout>
            <LivenessInfo />
          </MainLayout>
        </TokenGuard>
      </BackHandler>
    )
  },
  // liveness 팁 페이지
  {
    path: paths.liveTip,
    element: (
      <TokenGuard>
        <MainLayout>
          <LivenessTip />
        </MainLayout>
      </TokenGuard>
    )
  },
  // liveness 촬영
  {
    path: paths.live,
    element: (
      <TokenGuard>
        <MainLayout>
          <Liveness />
        </MainLayout>
      </TokenGuard>
    )
  },
  // ----------------------------------------------------------------------
  // 404
  {
    path: '*',
    element: (
      <MainLayout>
        <Page404 />
      </MainLayout>
    )
  }
]);
