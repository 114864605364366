import styled from '@emotion/styled';
import styles from 'src/assets/app.module.css';
import ModalHeader from 'src/components/ModalHeader';
import Radio_off from 'src/assets/images/ico-rado-off.svg';
import Radio_on from 'src/assets/images/ico-rado-on.svg';
import ButtonGroup from 'src/components/ButtonGroup';
import Button from 'src/components/Button';

const TextTitle = styled.div`
  text-align: left;
  line-height: 150%;
  font-size: var(--title3-size);
  color: var(--control-bold);
  font-family: var(--title3-family);
  font-weight: 700;
  margin-bottom: 32px;
`;

const TextBox = styled.dl`
  dt {
    font-size: var(--body1-size);
    line-height: 150%;
    font-family: var(--body1-family);
    color: var(--control-bold);
    font-weight: 700;
    margin-bottom: 4px;
    span {
      font-weight: normal;
    }
  }
  dd {
    font-size: var(--body2-size);
    line-height: 150%;
    color: var(--text-body);
  }
`;

const TableTitle = styled.div`
  text-align: left;
  line-height: 150%;
  font-size: var(--subtitle2-size);
  color: var(--control-bold);
  font-family: var(--subtitle2-family);
  margin-bottom: 8px;
  margin-top: 32px;
  font-weight: 700;
`;

const TableBox = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  border-top: 1px solid var(--border-secondary);

  th {
    border-right: 1px solid var(--control-neutral-01);
    background: var(--control-neutral-03);
    padding: 8px 4px;
    text-align: center;
    font-size: var(--caption-size);
    color: var(--text-Primary);
    font-family: var(--caption-family);
    border-bottom: 1px solid var(--control-neutral-01);
    vertical-align: middle;
  }
  td {
    padding: 8px;
    text-align: left;
    font-size: var(--caption-size);
    color: var(--text-colortext-body-2);
    font-family: var(--caption-family);
    border-bottom: 1px solid var(--control-neutral-01);
    vertical-align: middle;

    p {
      font-size: var(--body2-size);
      line-height: 140%;
      font-family: var(--body2-family);
      color: var(--text-primary);
      text-align: left;
      display: block;
      font-weight: 700;
    }
    p + ul {
      margin-top: 8px;
    }

    .subtext {
      font-size: var(--link_small-size);
      line-height: 140%;
      color: var(--text-primary);
      text-align: left;
      font-family: var(--link_small-family);
      font-weight: normal;

      strong {
        font-weight: 700;
      }
    }
  }
`;

const TextList = styled.ul`
  text-align: left;
  white-space: pre-line;
  list-style: none;
  width: 100%;

  li {
    position: relative;
    padding-left: 12px;
    font-size: var(--caption-size);
    line-height: 140%;
    font-family: var(--caption-family);
    color: var(--text-body);
    text-align: left;

    &:before {
      content: '• ';
      position: absolute;
      left: 0;
      display: inline-block;
    }

    &:not(:first-of-type) {
      margin-top: 8px;
    }
  }
`;

const RadioGroup = styled.fieldset`
  display: block;
  border: 0;
  text-align: right;
  margin-top: 12px;
  list-style: none;

  li {
    display: inline-block;
    .radio-icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      background-size: 20px 20px;
      border: 0px solid #e0e0e0;
      vertical-align: middle;
      background-image: url(${Radio_off});
    }
    .radio-icon.on {
      display: inline-block;
      width: 20px;
      height: 20px;
      background-size: 20px 20px;
      border: 0px solid #e0e0e0;
      vertical-align: middle;
      background-image: url(${Radio_on});
    }
    span {
      display: inline-block;
      padding-left: 0.3rem;
      vertical-align: middle;
      font-size: var(--link_small-size);
      line-height: 150%;
      font-family: var(--link_small-family);
      color: var(--text-body);
    }
    input[type='radio'] {
      display: none;
    }
    input[type='radio']:checked + .radio-icon {
      border: 0;
      background-image: url(${Radio_on});
    }
    input[type='checkbox'] {
      display: none;
    }
    input[type='checkbox']:checked + .radio-icon {
      border: 0;
      background-image: url(${Radio_on});
    }
  }
  li + li {
    margin-left: 24px;
  }
`;

const BtmRadio = styled.div`
  margin-top: 20px;
  p {
    font-size: var(--link_small-size);
    line-height: 140%;
    font-family: var(--font-hanwhagothic);
    color: var(--text-secondary);
    text-align: left;
    span {
      text-decoration: underline;
    }
  }
  fieldset {
    text-align: right;
    margin-top: 4px;

    span {
      font-size: var(--link_small-size);
      line-height: 150%;
      font-family: var(--body2-family);
      color: var(--text-body);
      text-align: left;
    }
  }
`;

export default function LivenessTerms({ close }) {
  return (
    <div className={styles.wrap}>
      <div className={styles.cont_box}>
        <div className={styles.cont_body}>
          <div className={styles.ContWrap}>
            <TextTitle>[필수] 개인(신용)정보 수집·이용제공 동의서[안면인식]</TextTitle>

            <TextBox>
              <dt>
                주식회사 한화생명보험 <span>귀중</span>
              </dt>
              <dd>
                한화생명보험과의 안면인식 본인확인과 관련하여 한화생명보험이 본인의 개인(신용)정보를 수집·이용하고자
                하는 경우에는 「개인정보보호법」, 「신용정보의 이용 및 보호에 관한 법률」 등 관련 법령에 따라 본인의
                동의가 필요합니다.
              </dd>
            </TextBox>

            <TableTitle>1. 수집 ·이용에 관한 사항</TableTitle>
            <TableBox>
              <colgroup>
                <col width="64"></col>
              </colgroup>
              <tr>
                <th>수집 이용 목적</th>
                <td>
                  <TextList>
                    <li>안면인식을 통한 본인확인 및 보험 서비스 제공</li>
                    <li>서비스 가입·이용·관리 및 개선</li>
                    <li>한화생명 대출이용을 위한 심사 및 거래유지</li>
                  </TextList>
                </td>
              </tr>
              <tr>
                <th>보유 및 이용기간</th>
                <td>
                  <p>
                    - (금융)거래 종료일로부터 5년 까지 보유·이용
                    <br />
                    &nbsp;&nbsp;&nbsp;단, 얼굴정보(특징정보)는 본인여부 확인 후 지체없이 삭제됩니다.
                  </p>
                  <TextList>
                    <li>
                      위 보유기간에서의 (금융)거래 종료일이란 “당사와 거래중인 모든 계약(보험·융자 등 상거래관계) 해지
                      및 서비스가 종료된 날”을 말합니다
                    </li>
                    <li>
                      (금융)거래 종료일 후에는 금융사고 조사, 분쟁 해결, 민원 처리, 법령상 의무이행을 위한 목적으로만
                      보유·이용됩니다.
                    </li>
                  </TextList>
                </td>
              </tr>
              <tr>
                <th>거부 권리 및 불이익</th>
                <td>
                  <TextList>
                    <li>
                      귀하는 동의를 거부하실 수 있습니다. 다만, 개인(신용)정보 수집·이용에 관한 동의는 안면인식 본인확인
                      이용 시 필수적 사항이므로, 위 사항에 동의하셔야만 (금융)거래관계의 설정 및 유지가 가능합니다.
                    </li>
                  </TextList>
                </td>
              </tr>
            </TableBox>

            <TableTitle>2. 수집 ·이용 항목</TableTitle>
            <TableBox>
              <colgroup>
                <col width="64"></col>
              </colgroup>
              <tr>
                <th rowSpan="2">민감정보</th>
                <td>
                  <p>얼굴정보(특징정보)</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="subtext">
                    위 <strong>민감정보 수집·이용</strong>에 동의하십니까?
                  </p>
                  <RadioGroup>
                    <li>
                      <i className="radio-icon"></i>
                      <span>동의하지 않음</span>
                    </li>
                    <li>
                      <i className="radio-icon"></i>
                      <span>동의함</span>
                    </li>
                  </RadioGroup>
                </td>
              </tr>
              <tr>
                <th>개인(신용) 정보</th>
                <td>-</td>
              </tr>
              <tr>
                <th>일반개인 정보</th>
                <td>얼굴정보(원본정보)</td>
              </tr>
            </TableBox>
            <BtmRadio>
              <p>
                위 개인신용정보 <span>수집·이용</span>에 동의하십니까?
              </p>

              <RadioGroup>
                <li>
                  <i className="radio-icon"></i>
                  <span>동의하지 않음</span>
                </li>
                <li>
                  <i className="radio-icon"></i>
                  <span>동의함</span>
                </li>
              </RadioGroup>
            </BtmRadio>
          </div>
          <div className={styles.btm_box}>
            <ButtonGroup gap="true" main>
              <Button text="동의" onClick={close} fill="primary" size="large" status="active" />
            </ButtonGroup>
          </div>
        </div>
      </div>
    </div>
  );
}
