import { RivConstant } from 'src/constants/rivConstant';

/**
 * WebView 에러 클래스
 * 비지니스 에러 외의 catch 절에서 잡히는 에러를 처리하기 위한 클래스
 * @constructor Error
 * */
export class RivError extends Error {
  constructor(error, valueJson = {}) {
    super(error.message);
    this.rivsRqstId = sessionStorage.getItem(RivConstant.REQUEST_ID);
    this.valueJson = JSON.stringify(valueJson);
  }

  getWebViewLogDto(location) {
    return {
      rivsRqstId: this.rivsRqstId,
      location,
      logMsg: this.message,
      jsonValue: this.valueJson
    };
  }
}
