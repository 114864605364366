import styled from '@emotion/styled';
import Button from './Button';
import ButtonGroup from './ButtonGroup';

const Title = styled.div`
  flex: 1;
  width: 100%;
  position: relative;
  font-size: var(--title3-size);
  font-family: var(--title3-family);
  font-weight: 700;
  line-height: 150%;
  color: var(--text-primary);
  text-align: left;
  display: inline-block;
`;
const Top = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--spacing-150) 0px var(--spacing-300);
`;
const Text = styled.div`
  position: relative;
  font-size: var(--body2-size);
  line-height: 150%;
  font-family: var(--body2-family);
  color: var(--text-body);
  text-align: left;
  display: inline-block;
  white-space: pre-line;
`;
const Body = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px var(--spacing-250);
  font-size: var(--body2-size);
  color: var(--text-subdued);
`;

const DialogBox = styled.div`
  width: calc(100% - 40px);
  position: relative;
  border-radius: var(--radius-xl);
  background-color: var(--control-neutral);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--spacing-250);
  box-sizing: border-box;
  max-height: 400px;
  text-align: left;
  font-size: var(--h3-size);
  color: var(--text-primary);
  font-family: var(--subtitle2);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (orientation: landscape) {
    width: 60%;
  }
`;

const DialogWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--background-dimmed);
  width: 100%;
  height: 100%;
  z-index: 10;
`;

function Dialog({ title, description, buttonContents }) {
  return (
    <DialogWrap>
      <DialogBox>
        <Top>
          <Title>{title}</Title>
        </Top>
        <Body>
          <Text>{description}</Text>
        </Body>

        <ButtonGroup gap="true" dialog>
          {buttonContents.map((item, index) => (
            <Button
              key={`item-${index}`}
              type={item.type}
              text={item.text}
              fill={item.fill}
              size="large"
              status="active"
              onClick={item.onClick}
            />
          ))}
        </ButtonGroup>
      </DialogBox>
    </DialogWrap>
  );
}

export default Dialog;
