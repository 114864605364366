// react
import { useCallback, useEffect, useState, useRef } from 'react';

// recoil
import { useRecoilValue } from 'recoil';
import { channelInfoState, deviceInfoState } from 'src/recoil/recoil';

// libraries
import { useNavigate } from 'react-router-dom';

// apis
import { personalInfoConsentApi } from 'src/apis/apis';

// hooks
import { useClose } from 'src/hooks/use-close';
import { useCalledBy } from 'src/hooks/use-called-by';

// constants
import { paths } from 'src/routes/path';
import { CHANNEL_CODE, FACE_MODE, OS_TYPE, RivConstant } from 'src/constants/rivConstant';

// components
import Header from 'src/components/Header';
import Button from 'src/components/Button';
import Dialog from 'src/components/Dialog';
import TextTitle from 'src/components/TextTitle';
import Copyright from 'src/components/Copyright';
import ButtonGroup from 'src/components/ButtonGroup';
import LivenessTerms from 'src/pages/liveness-terms';

// style
import styled from '@emotion/styled';
import styles from 'src/assets/app.module.css';
import Step6_img from 'src/assets/images/face_camera.svg';
import Static_img from 'src/assets/images/img-step6.svg';
import Radio_off from 'src/assets/images/ico-rado-off.svg';
import Radio_on from 'src/assets/images/ico-rado-on.svg';
import Right from 'src/assets/images/right.svg';
import { NativeBridge } from 'src/utils/nativeBridge';

const ImgBox = styled.div`
  text-align: center;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TextList = styled.ul`
  text-align: left;
  white-space: pre-line;
  list-style: none;
  margin-top: 32px;
  width: 100%;

  li {
    position: relative;
    padding-left: 10px;
    font-size: var(--body2-size);
    font-family: var(--body2);
    line-height: 150%;
    color: #8c959d;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 7px;
      display: inline-block;
      width: 4px;
      height: 4px;
      border-radius: 20px;
      background-color: #8c959d;
    }

    &:not(:first-of-type) {
      margin-top: 8px;
    }
  }
`;

const RadioGroup = styled.fieldset`
  display: block;
  border: 0;
  text-align: right;
  margin-top: 12px;
  align-items: center;

  label {
    cursor: pointer;
    .radio-icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      background-size: 20px 20px;
      border: 0px solid #e0e0e0;
      vertical-align: middle;
      background-image: url(${Radio_off});
    }
    span {
      display: inline-block;
      padding-left: 0.3rem;
      vertical-align: middle;
      font-size: var(--link_small-size);
      line-height: 150%;
      font-family: var(--link_small-family);
      color: var(--text-body);
    }
    input[type='radio'] {
      display: none;
    }
    input[type='radio']:checked + .radio-icon {
      border: 0;
      background-image: url(${Radio_on});
    }
    input[type='checkbox'] {
      display: none;
    }
    input[type='checkbox']:checked + .radio-icon {
      border: 0;
      background-image: url(${Radio_on});
    }
  }
  label + label {
    margin-left: 24px;
  }
`;

const BtmRadio = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
  fieldset {
    text-align: left;
    margin-top: 0;
    span {
      font-size: var(--body2-size);
      line-height: 150%;
      font-family: var(--body2-family);
      color: var(--text-primary);
      text-align: left;
      font-weight: 700;
    }
  }
`;

const TermsButton = styled.div`
  .right-icon {
    display: block;
    width: 16px;
    height: 16px;
    background-size: 20px 20px;
    border: 0px solid #e0e0e0;
    vertical-align: middle;
    background-image: url(${Right});
  }
`;

export default function LivenessInfo() {
  // navigate
  const navigate = useNavigate();
  // recoil
  const channelInfo = useRecoilValue(channelInfoState);
  const deviceInfo = useRecoilValue(deviceInfoState);
  // state
  const [personalConsent, setPersonalConsent] = useState(false); // 개인정보 동의여부
  const [openTerms, setOpenTerms] = useState(false); // 약관상세모달 Open Flag
  const [openModal, setOpenModal] = useState(false); // 에러모달 Open Flag
  const [openExitModal, setOpenExitModal] = useState(false); // 뒤로가기시 종료 확인 모달
  const [message, setMessage] = useState(
    '신분증 사진과 얼굴을 비교하여\n본인 여부를 확인합니다.\n얼굴을 천천히 조금만 돌려서 얼굴인증을 진행해 주세요.'
  );
  const [openConnectionError, setOpenConnectionError] = useState(false); // 통신에러 모달
  // hook
  const { tmmPageSend } = useCalledBy();
  const { webClose } = useClose();
  // ref
  const isDisableAPI = useRef(false); // API 진행 FLAG

  useEffect(() => {
    // TMM 채널 페이지 이동 메시지 전달
    tmmPageSend(6);
    // 한화생명 앱 카메라 권한 확인
    callHciCameraAuth();
  }, []);

  useEffect(() => {
    /* 안면인식 이탈자 여부와 정/동적 모드에 따라 안내 문구 변경 */
    if (channelInfo.faceStopYn === 'N' && channelInfo.faceMode === FACE_MODE.DYNAMIC) {
      setMessage(
        '신분증 사진과 얼굴을 비교하여\n본인 여부를 확인합니다.\n얼굴을 천천히 조금만 돌려서 얼굴인증을 진행해 주세요.'
      );
    } else if (channelInfo.faceStopYn === 'N' && channelInfo.faceMode === FACE_MODE.STATIC) {
      setMessage('신분증 사진과 얼굴을 비교하여\n본인 여부를 확인합니다.');
    } else if (channelInfo.faceStopYn === 'Y' && channelInfo.faceMode === FACE_MODE.DYNAMIC) {
      setMessage(
        '신분증 확인되었습니다.\n신분증 사진과 얼굴을 비교하여\n본인 여부를 확인합니다.\n얼굴을 천천히 조금만 돌려서 얼굴인증을 진행해 주세요'
      );
    } else if (channelInfo.faceStopYn === 'Y' && channelInfo.faceMode === FACE_MODE.STATIC) {
      setMessage('신분증 확인되었습니다.\n신분증 사진과 얼굴을 비교하여\n본인여부를 확인합니다.');
    }
  }, [channelInfo.faceStopYn, channelInfo.faceMode]);

  /*한화생명 앱에서 안드로이드 접속 할 경우 권한요청 진행. (브릿지통신 이용)*/
  const callHciCameraAuth = () => {
    if (channelInfo.channel === CHANNEL_CODE.HCI && deviceInfo.os === OS_TYPE.ANDROID) {
      const args = {
        permissions: ['android.permission.CAMERA', 'android.permission.RECORD_AUDIO'],
        title: '카메라 권한 요청',
        content:
          '비대면 인증을 위해 신분증과 얼굴을 촬영해야\n합니다.\n카메라 이용을 위해 휴대폰 설정 > 어플리케이션에서 해당 앱의 권한을 허용해 주세요.'
      };
      NativeBridge.call('wallet', 'requestPermission', args, 'cameraAuthCallback');
    }
  };

  /*한화생명앱 카메라 권한 요청 브릿지통신 콜백 핸들러*/
  window.cameraAuthCallback = (response) => {
    console.log('===== [cameraAuthCallback 호출] =====');
    if (response) {
      const jsonResponse = decodeURIComponent(response);
      const parsedResponse = JSON.parse(jsonResponse);
      const parsedData = JSON.parse(parsedResponse.resData);
      console.log('===== [cameraAuthCallback] ====== parsedResponse: ', parsedResponse);
      console.log('===== [cameraAuthCallback] ====== parsedData: ', parsedData);
    }
  };

  /**-------------------------------- apis --------------------------------------*/
  /**
   * 동의하고 촬영하기 버튼 클릭이벤트
   * 개인정보동의 API 호출
   * */
  const handleNextClick = async () => {
    if (isDisableAPI.current) {
      return;
    }
    isDisableAPI.current = true;
    setPersonalConsent(true);
    const data = { rivsRqstId: sessionStorage.getItem(RivConstant.REQUEST_ID) };
    try {
      const response = await personalInfoConsentApi(data);
      if (response.header.rsltCode === '0000') {
        if (channelInfo.faceMode === FACE_MODE.DYNAMIC) {
          // 동적모드 일 경우 안면인식 팁 페이지 이동
          navigate(paths.liveTip);
        } else {
          navigate(paths.live);
        }
      } else {
        setOpenModal(true);
        console.error('===== [개인정보동의 API 에러] ===== response.header', response.header);
      }
    } catch (error) {
      console.error('===== [개인정보동의 API 통신에러] ===== error: ', error);
      setOpenConnectionError(true);
    } finally {
      isDisableAPI.current = false;
    }
  };

  /**-------------------------------- 이벤트 헨들러 --------------------------------------*/
  /*종료확인모달 - 확인 클릭이벤트*/
  const handleExitConfirm = () => {
    setOpenExitModal(false);
    webClose();
  };

  /*종료확인모달 - 취소 클릭이벤트*/
  const handleExitCancel = () => {
    setOpenExitModal(false);
  };

  /*개인정보 동의 체크박스 변경이벤트*/
  const handlePersonalChange = (e) => {
    console.log('===== [handlePersonalChange] ===== checked : ', e.target.checked);
    setPersonalConsent(e.target.checked);
  };

  /*약관 열기*/
  const handleTermsOpen = () => {
    setOpenTerms(true);
  };

  /*약관 닫기*/
  const handleTermsClose = useCallback(() => {
    setPersonalConsent(true);
    setOpenTerms(false);
  }, [setOpenTerms]);

  /*에러모달 - 확인 클릭이벤트*/
  const handleConfirmClick = () => {
    setOpenModal(false);
  };
  if (openTerms) {
    return <LivenessTerms close={handleTermsClose} />;
  }

  /*통신에러모달 - 확인 클릭이벤트*/
  const handleConnectionExitClick = () => {
    setOpenConnectionError(false);
    webClose();
  };

  return (
    <>
      <div className={styles.cont_box}>
        <Header step="75%" />
        <div className={styles.cont_body}>
          <div className={styles.ContWrap}>
            <TextTitle size="xlarge" type="title" title="얼굴인증" description={message} />

            <ImgBox>
              <img
                src={channelInfo.faceMode === FACE_MODE.DYNAMIC ? Step6_img : Static_img}
                alt="얼굴인증 샘플 이미지"
              />

              <TextList>
                <li>영역 안에 얼굴을 맞추고 정면을 바라봐주세요</li>
                <li>얼굴이 정상 인식되면 자동으로 촬영합니다</li>
                <li>마스크, 선글라스와 같이 얼굴을 가리는 방해물은 제거 후 촬영해주세요</li>
              </TextList>
            </ImgBox>
            <BtmRadio>
              <RadioGroup>
                <label>
                  <input type="checkbox" name="personal" checked={personalConsent} onChange={handlePersonalChange} />
                  <i className="radio-icon"></i>
                  <span>[필수] 개인(신용)정보 수집 및 이용동의</span>
                </label>
              </RadioGroup>
              <TermsButton>
                <i className="right-icon" onClick={handleTermsOpen}></i>
              </TermsButton>
            </BtmRadio>
          </div>
          <div className={styles.btm_box}>
            <ButtonGroup gap="true" main>
              <Button text="동의하고 촬영하기" fill="primary" size="large" status="active" onClick={handleNextClick} />
            </ButtonGroup>

            <Copyright />
          </div>
          {openModal && (
            <Dialog
              title={'개인정보동의 처리 에러 발생'}
              description={'개인정보동의 처리 중 에러가 발생하였습니다.\n다시 시도해 주세요.'}
              buttonContents={[
                {
                  text: '확인',
                  fill: 'primary',
                  onClick: handleConfirmClick
                }
              ]}
            />
          )}
          {openExitModal && (
            <Dialog
              title="인증 종료"
              description={'비대면 인증 서비스가 완료되지 않았습니다.\n인증서비스를 종료합니다.'}
              buttonContents={[
                {
                  text: '취소',
                  fill: 'neutral1',
                  onClick: handleExitCancel
                },
                {
                  text: '확인',
                  fill: 'primary',
                  onClick: handleExitConfirm
                }
              ]}
            />
          )}
          {openConnectionError && (
            <Dialog
              title={'통신 에러 발생'}
              description={'통신 에러가 발생하였습니다.\n잠시 후에 다시 시도해 주세요.'}
              buttonContents={[
                {
                  text: '확인',
                  fill: 'primary',
                  onClick: handleConnectionExitClick
                }
              ]}
            />
          )}
        </div>
      </div>
    </>
  );
}
