import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import Spinner_img from 'src/assets/images/img-loading.svg';
import { useEffect } from 'react';

export const spin = keyframes`
    to { transform: rotate(360deg);}
`;

const LoadingWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--background-dimmed);
  width: 100%;
  height: 100%;
  z-index: 10;
`;

const LoadingBox = styled.div`
  width: calc(100% - 40px);
  position: relative;
  border-radius: var(--radius-xl);
  background-color: var(--background-dimmed);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 32px;
  box-sizing: border-box;
  gap: 16px;
  text-align: center;
  font-size: var(--body2-size);
  color: var(--text-inverse);
  font-family: var(--body2);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (orientation: landscape) {
    width: 60%;
  }
`;

const Spinner = styled.div`
  display: inline-block;

  & img {
    animation: ${spin} 2000ms infinite linear;
  }
`;

const DivRoot = styled.div`
  width: 100%;
  position: relative;
  font-size: var(--body2-size);
  line-height: 150%;
  font-family: var(--body2);
  color: var(--text-inverse);
  text-align: center;
`;

const Text = styled.div`
  position: relative;
  font-size: var(--body2-size);
  line-height: 150%;
  font-family: var(--body2-family);
  color: var(--text-inverse);
  text-align: center;
  white-space: pre-line;
`;

function Loading({ description }) {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <LoadingWrap>
      <LoadingBox>
        <Spinner>
          <img src={Spinner_img} alt="Loading Img" />
        </Spinner>

        <DivRoot>
          <Text>{description}</Text>
        </DivRoot>
      </LoadingBox>
    </LoadingWrap>
  );
}

export default Loading;
