import { useCallback, useEffect, useState } from 'react';
import { RivConstant } from 'src/constants/rivConstant';
import Dialog from 'src/components/Dialog';
import { useClose } from 'src/hooks/use-close';

/*토큰 유무 확인 컴포넌트*/
export default function TokenGuard({ children }) {
  const [tokenError, setTokenError] = useState(false);
  const { webClose } = useClose();

  /**-------------------------------- useEffect --------------------------------------*/
  useEffect(() => {
    /*토큰 유무 검사*/
    isTokenPresent();
  }, []);

  /**-------------------------------- function --------------------------------------*/
  /*토큰 유무 검사*/
  const isTokenPresent = useCallback(() => {
    const getToken = sessionStorage.getItem(RivConstant.ACCESS_TOKEN_KEY);

    if (!getToken || getToken.trim().length < 1) {
      setTokenError(true);
    }
  }, []);

  /**-------------------------------- 이벤트핸들러 --------------------------------------*/
  /*토큰에러모달 - 확인 클릭이벤트*/
  const handleModalClose = useCallback(() => {
    setTokenError(false);
    webClose();
  }, []);

  return (
    <>
      {children}
      {tokenError && (
        <Dialog
          title="토큰 에러 발생"
          description={'토큰 검증 에러가 발생하였습니다.\n다시 시도해 주세요.'}
          buttonContents={[
            {
              text: '확인',
              fill: 'primary',
              onClick: handleModalClose
            }
          ]}
        />
      )}
    </>
  );
}
