import { RivConstant } from 'src/constants/rivConstant';

/**
 * WebView 비즈니스 에러 클래스
 * WebView 에서 발생하는 에러 로그 적재를 위한 클래스
 * @constructor message(에러 메시지), valueJson(주요 변수 값)
 * */
export class BizError extends Error {
  constructor(message, valueJson) {
    super(message);
    this.rivsRqstId = sessionStorage.getItem(RivConstant.REQUEST_ID);
    this.valueJson = JSON.stringify(valueJson);
  }

  getWebViewLogDto(location) {
    return {
      rivsRqstId: this.rivsRqstId,
      location: `${location} ${this.stack.split('\n')[1].trim()}`,
      logMsg: this.message,
      jsonValue: this.valueJson
    };
  }
}
