// react
import { useEffect } from 'react';

// libraries
import { useNavigate } from 'react-router-dom';

// hooks
import { useCalledBy } from 'src/hooks/use-called-by';

// constants
import { paths } from 'src/routes/path';

// components
import Button from 'src/components/Button';
import Header from 'src/components/Header';
import Copyright from 'src/components/Copyright';
import TextTitle from 'src/components/TextTitle';
import ButtonGroup from 'src/components/ButtonGroup';

// style
import styled from '@emotion/styled';
import styles from 'src/assets/app.module.css';
import { scanner } from 'src/scripts/robiscan';
import { CHANNEL_CODE, OS_TYPE } from 'src/constants/rivConstant';
import { NativeBridge } from 'src/utils/nativeBridge';
import { useRecoilValue } from 'recoil';
import { channelInfoState, deviceInfoState } from 'src/recoil/recoil';

const ImgBox = styled.div`
  text-align: center;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TextList = styled.ul`
  text-align: left;
  white-space: pre-line;
  list-style: none;
  margin-top: 32px;
  width: 100%;

  li {
    position: relative;
    padding-left: 10px;
    font-size: var(--body2-size);
    font-family: var(--body2);
    line-height: 150%;
    color: #8c959d;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 7px;
      display: inline-block;
      width: 4px;
      height: 4px;
      border-radius: 20px;
      background-color: #8c959d;
    }

    &:not(:first-of-type) {
      margin-top: 8px;
    }
  }
`;

export default function Step2() {
  // navigate
  const navigate = useNavigate();
  // recoil
  const channelInfo = useRecoilValue(channelInfoState);
  const deviceInfo = useRecoilValue(deviceInfoState);
  // hook
  const { tmmPageSend } = useCalledBy();

  useEffect(() => {
    // TMM 채널 페이지 이동 메시지 전달
    tmmPageSend(2);
    // 한화생명 앱 카메라 권한 확인
    callHciCameraAuth();
  }, []);

  /*한화생명 앱에서 안드로이드 접속 할 경우 권한요청 진행. (브릿지통신 이용)*/
  const callHciCameraAuth = () => {
    if (channelInfo.channel === CHANNEL_CODE.HCI && deviceInfo.os === OS_TYPE.ANDROID) {
      // 한화생명 앱에서 안드로이드 접속 할 경우 우선 권한요청 진행. (브릿지통신 이용)
      const args = {
        permissions: ['android.permission.CAMERA', 'android.permission.RECORD_AUDIO'],
        title: '카메라 권한 요청',
        content:
          '비대면 인증을 위해 신분증과 얼굴을 촬영해야\n합니다.\n카메라 이용을 위해 휴대폰 설정 > 어플리케이션에서 해당 앱의 권한을 허용해 주세요.'
      };
      NativeBridge.call('wallet', 'requestPermission', args, 'cameraAuthCallback');
    }
  };

  /*한화생명앱 카메라 권한 요청 브릿지통신 콜백 핸들러*/
  window.cameraAuthCallback = (response) => {
    console.log('===== [cameraAuthCallback 호출] =====');
    if (response) {
      const jsonResponse = decodeURIComponent(response);
      const parsedResponse = JSON.parse(jsonResponse);
      const parsedData = JSON.parse(parsedResponse.resData);
      console.log('===== [cameraAuthCallback] ====== parsedResponse: ', parsedResponse);
      console.log('===== [cameraAuthCallback] ====== parsedData: ', parsedData);
    }
  };

  /**-------------------------------- 이벤트 헨들러 --------------------------------------*/
  // 촬영 버튼 클릭이벤트
  const handleNextClick = () => {
    navigate(paths.step3);
  };

  return (
    <>
      <div className={styles.cont_box}>
        <Header step="25%" />
        <div className={styles.cont_body}>
          <div className={styles.ContWrap}>
            <TextTitle
              size="xlarge"
              type="title"
              title="신분증 촬영"
              description={`주민등록증 또는 운전면허증을 준비해 주세요.`}
            />

            <ImgBox>
              <img src="/images/id-card.svg" className={styles.step2_img} alt="주민등록증 샘플 이미지" />

              <TextList>
                <li>복사본이나 사진은 사용할 수 없습니다.</li>
                <li>오염이나 훼손된 신분증으로는 확인 어렵습니다.</li>
              </TextList>
            </ImgBox>
          </div>
          <div className={styles.btm_box}>
            <ButtonGroup gap="true" main>
              <Button text="신분증 촬영" fill="primary" size="large" status="active" onClick={handleNextClick} />
            </ButtonGroup>
            <Copyright />
          </div>
        </div>
      </div>
    </>
  );
}
