// react
import { useEffect } from 'react';

// libraries
import { useNavigate } from 'react-router-dom';

// hooks
import { useCalledBy } from 'src/hooks/use-called-by';

// constants
import { paths } from 'src/routes/path';

// style
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import styles from 'src/assets/app.module.css';
import Button from 'src/components/Button';
import ButtonGroup from 'src/components/ButtonGroup';
import Tip_img0 from 'src/assets/images/tip_img0.svg';
import Tip_img1 from 'src/assets/images/tip_img1.svg';
import Tip_img2 from 'src/assets/images/tip_img2.svg';

export const fade = keyframes`
	0% {
		opacity: 1;
	}
	20% {
		opacity: 1;
	}
	34% {
		opacity: 0;
	}
	90% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
`;

const TipBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  font-size: var(--body2-size);
  font-family: var(--body2);
  color: var(--text-subdued);

  & p {
    font-size: 32px;
    line-height: 150%;
    font-family: var(--subtitle2-family);
    color: #272b2f;
    text-align: center;
    display: block;
    font-weight: 800;
    margin-bottom: 4px;
  }

  & span {
    font-size: 17px;
    line-height: 150%;
    font-family: var(--body1-family);
    color: #fa6600;
    text-align: center;
    display: block;
    margin-bottom: 32px;
  }

  @media (min-height: 500px) and (max-height: 580px) {
    & p {
      font-size: 26px;
      line-height: 140%;
    }
    & span {
      font-size: 14px;
      line-height: 130%;
      margin-bottom: 22px;
    }
  }
  @media screen and (max-height: 500px) {
    & p {
      font-size: 22px;
      line-height: 140%;
    }
    & span {
      font-size: 13px;
      line-height: 130%;
      margin-bottom: 16px;
    }
  }
`;

const ImgChange = styled.div`
  position: relative;
  display: block;
  width: 160px;
  max-width: 100%;
  height: 320px;
  margin: 0 auto;

  .TipImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    animation-name: ${fade};
    animation-duration: 8s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }

  #TipImg1 {
    animation-delay: 0s;
    background-image: url(${Tip_img0});
  }
  #TipImg2 {
    animation-delay: 1.3s;
    background-image: url(${Tip_img1});
  }
  #TipImg3 {
    animation-delay: 3.3s;
    background-image: url(${Tip_img0});
  }
  #TipImg4 {
    animation-delay: 5.3s;
    background-image: url(${Tip_img2});
  }

  @media (min-height: 500px) and (max-height: 580px) {
    width: 150px;
    height: 300px;
  }
  @media screen and (max-height: 500px) {
    width: 140px;
    height: 280px;
  }
`;

export default function LivenessTip() {
  const navigate = useNavigate();
  // hook
  const { tmmPageSend } = useCalledBy();

  /**-------------------------------- useEffect --------------------------------------*/
  useEffect(() => {
    // TMM 채널 페이지 이동 메시지 전달
    tmmPageSend(7);
  }, []);

  /**-------------------------------- 이벤트 헨들러 --------------------------------------*/
  /*얼굴인증 시작하기 클릭이벤트*/
  const handleStartClick = () => {
    navigate(paths.live);
  };

  return (
    <div className={`${styles.wrap} ${styles.tip_bg}`}>
      <div className={styles.cont_box}>
        <div className={styles.cont_body}>
          <div className={styles.ContWrap}>
            <TipBox>
              <p>얼굴인증 촬영 Tip</p>

              <span>
                안내문구에 따라 고개를 조금만 돌려도
                <br />
                인식 성공 확률이 높아집니다
              </span>

              <ImgChange>
                <div className="TipImg" id="TipImg1"></div>
                <div className="TipImg" id="TipImg2"></div>
                <div className="TipImg" id="TipImg3"></div>
                <div className="TipImg" id="TipImg4"></div>
              </ImgChange>
            </TipBox>
          </div>

          <div className={styles.btm_box}>
            <ButtonGroup gap="true" main>
              <Button text="얼굴인증 시작하기" fill="primary" size="large" status="active" onClick={handleStartClick} />
            </ButtonGroup>
          </div>
        </div>
      </div>
      <div className={styles.tip_gradient} />
    </div>
  );
}
