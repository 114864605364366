// react
import React, { useEffect, useRef, useState } from 'react';

// recoil
import { useRecoilValue } from 'recoil';
import { channelInfoState, deviceInfoState, ocrFaceInfoState } from 'src/recoil/recoil';

// libraries
import { useNavigate } from 'react-router-dom';

// apis
import { authenticateWithFaceApi, detectionFailApi, uploadFaceVideoApi, webViewLogsApi } from 'src/apis/apis';

// hooks
import { useClose } from 'src/hooks/use-close';
import { useCalledBy } from 'src/hooks/use-called-by';

// constants
import { paths } from 'src/routes/path';
import { CHANNEL_CODE, FACE_MIME_TYPE, FACE_MODE, OS_TYPE, RivConstant } from 'src/constants/rivConstant';

// utils
import { NativeBridge } from 'src/utils/nativeBridge';
import { removeBase64Prefix } from 'src/utils/commonUtils';

// error
import { BizError } from 'src/errors/bizError';
import { RivError } from 'src/errors/rivError';

// component
import Dialog from 'src/components/Dialog';
import Loading from 'src/components/Loading';

// style
import styled from '@emotion/styled';
import Face_bg from 'src/assets/images/face_bg.svg';
import { keyframes } from '@emotion/react';
import Face_chk_error from 'src/assets/images/face_chk_error.svg';
import Face_chk_sucess from 'src/assets/images/face_chk_sucess.svg';
import Check_off from 'src/assets/images/ico-check-off.svg';
import Check_on from 'src/assets/images/ico-check-on.svg';

export const arrow1 = keyframes`
    0% { fill: none; }
	20%, 100% { fill: #F5F6F7; }
`;
export const arrow2 = keyframes`
    0% { fill: none; }
	20%, 65% { fill: #F5F6F7; }
`;
export const arrow3 = keyframes`
    0% { fill: none; }
	20%, 40% { fill: #F5F6F7; }
`;

/* 241230수정 S */
export const circleClip = keyframes`
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
    100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
`;
/* 241230수정 E */

const BgRoot = styled.div`
  position: relative;
  height: 100%;
  height: 100dvh;
  background-color: rgba(0 0 0 / 0.9);
`;

const SubBox = styled.div`
  position: relative;
  height: 100%;
  height: 100dvh;

  //@media (min-width: 660px) and (max-width: 720px) and (min-height: 560px) and (min-aspect-ratio: 1.05/1) {
  @media (min-width: 590px) and (max-width: 720px) and (min-height: 550px) and (min-aspect-ratio: 0.982/1) {
    width: 60%;
    transform: translate(-50%, 0) rotate(0);
    transform-origin: top left;
    top: 0;
    left: 50%;
    height: 100%;
  }
  @media (min-width: 780px) and (max-width: 850px) and (min-aspect-ratio: 0.982/1) {
    width: 43%;
    transform: translate(-50%, 0) rotate(0);
    transform-origin: top left;
    top: 0;
    left: 50%;
    height: 100%;
  }
  @media (min-aspect-ratio: 2/1) and (orientation: landscape) {
    transform: rotate(-90deg);
    transform-origin: top left;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100dvh;
    height: 100dvw;
  }
`;

const TextTitle = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  font-size: var(--title2-size);
  color: var(--text-inverse);
  font-family: var(--title2- family);
  padding: 32px 20px 0;
  @media screen and (max-height: 580px) {
    padding: 22px 20px 0;
  }
`;

const Maskimg = styled.div`
  position: relative;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
`;

const CameraVideo = styled.video`
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  mask-image: url(${Face_bg});
  mask-repeat: no-repeat;
  mask-position: center center;
  mask-size: 100%;
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
`;

const FaceChkError = styled.div`
  position: absolute;
  z-index: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  background-image: url(${Face_chk_error});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
`;

const FaceChkSucess = styled.div`
  position: absolute;
  z-index: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  background-image: url(${Face_chk_sucess});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
`;

const CheckList = styled.ul`
  position: absolute;
  right: 20px;
  top: 50%;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
  transform: translateY(${(props) => `${(props.width - 40) / 2 - 72}px`});
  li {
    display: inline-block;
    font-size: 0;
    span {
      display: inline-block;
      width: 16px;
      height: 16px;
      background-image: url(${Check_off});
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100%;
      &.on {
        background-image: url(${Check_on});
      }
    }
  }
  @media (min-width: 590px) and (max-width: 720px) and (min-height: 550px) and (min-aspect-ratio: 0.982/1) {
    transform: translateY(${(props) => `${((props.width - 40) * 0.6) / 2 - 90}px`});
  }
  @media (min-width: 780px) and (max-width: 850px) and (min-aspect-ratio: 0.982/1) {
    transform: translateY(${(props) => `${((props.width - 40) * 0.45) / 2 - 85}px`});
  }
  @media (min-aspect-ratio: 2/1) and (orientation: landscape) {
    transform: translateY(${(props) => `${(props.width - 40) / 2 - 80}px`});
  }
`;

const Textinfo = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  padding: 0 20px;
  font-size: var(--subtitle1-size);
  line-height: 150%;
  font-family: var(--body1-family);
  color: var(--system-white);
  text-align: center;
  display: inline-block;
  transform: translate(-50%, ${(props) => `${props.width / 2}px`});
  @media (min-width: 590px) and (max-width: 720px) and (min-height: 550px) and (min-aspect-ratio: 0.982/1) {
    transform: translate(-50%, ${(props) => `${(props.width * 0.6) / 2}px`});
  }
  @media (min-width: 780px) and (max-width: 850px) and (min-aspect-ratio: 0.982/1) {
    transform: translate(-50%, ${(props) => `${(props.width * 0.45) / 2 - 5}px`});
    line-height: 115%;
  }
  @media (min-aspect-ratio: 2/1) and (orientation: landscape) {
    transform: translate(-50%, ${(props) => `${props.height / 2}px`});
    line-height: 140%;
  }
`;

const FaceArrow = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 66px;
    height: 53px;
    transform: translate(-50%, -${(props) => `${props.width / 2 + 51}px`});
    @media (min-height: 500px) and (max-height: 580px) {
      width: 56px;
      height: 43px;
      transform: translate(-50%, -${(props) => `${props.width / 2 + 45}px`});
    }
    @media screen and (max-height: 500px) {
      width: 46px;
      height: 33px;
      transform: translate(-50%, -${(props) => `${props.width / 2 + 25}px`});
    }
    @media (min-width: 590px) and (max-width: 720px) and (min-height: 550px) and (min-aspect-ratio: 0.982/1) {
      transform: translate(-50%, -${(props) => `${(props.width * 0.6) / 2 + 45}px`});
    }
    @media (min-width: 780px) and (max-width: 850px) and (min-aspect-ratio: 0.982/1) {
      transform: translate(-50%, -${(props) => `${(props.width * 0.45) / 2 + 25}px`});
    }
    @media (min-aspect-ratio: 2/1) and (orientation: landscape) {
      transform: translate(-50%, -${(props) => `${props.height / 2 + 51}px`});
    }
  }

  .FaceArrowNone {
    display: none;
  }

  .FaceArrowLeft,
  .FaceArrowRight {
    #arrow1 {
      animation-name: ${arrow1};
      animation-duration: 1.5s;
      animation-delay: -0.1s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
    #arrow2 {
      animation-name: ${arrow2};
      animation-duration: 1.5s;
      animation-delay: 0.15s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
    #arrow3 {
      animation-name: ${arrow3};
      animation-duration: 1.5s;
      animation-delay: 0.35s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
  }

  .FaceArrowRight {
    transform: translate(-50%, -${(props) => `${props.width / 2 + 51}px`}) rotate(180deg);
    @media (min-height: 500px) and (max-height: 580px) {
      transform: translate(-50%, -${(props) => `${props.width / 2 + 45}px`}) rotate(180deg);
    }
    @media screen and (max-height: 500px) {
      transform: translate(-50%, -${(props) => `${props.width / 2 + 25}px`}) rotate(180deg);
    }
    @media (min-width: 590px) and (max-width: 720px) and (min-height: 550px) and (min-aspect-ratio: 0.982/1) {
      transform: translate(-50%, -${(props) => `${(props.width * 0.6) / 2 + 41}px`}) rotate(180deg);
    }
    @media (min-width: 780px) and (max-width: 850px) and (min-aspect-ratio: 0.982/1) {
      transform: translate(-50%, -${(props) => `${(props.width * 0.45) / 2 + 25}px`}) rotate(180deg);
    }
    @media (min-aspect-ratio: 2/1) and (orientation: landscape) {
      transform: translate(-50%, -${(props) => `${props.height / 2 + 51}px`}) rotate(180deg);
    }
  }
`;

const TextCount = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 10;
  font-size: 60px;
  line-height: 90px;
  font-weight: 700;
  color: white;
`;

const FaceLoader = styled.div`
  position: absolute;
  z-index: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  overflow: hidden;

  .FaceLoading {
    width: 100%;
    height: 100%;
    transform: rotate(45deg);

    #circle {
      stroke: transparent;
      &.play {
        stroke: #ffffff;
        animation-play-state: running;
        animation-name: ${circleClip};
        animation-duration: 2.1s;
        //animation-delay: -0.1s;
        animation-timing-function: linear;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
      }
    }
  }
`;

const Faceinfo = styled.div`
  position: absolute;
  left: 50%;
  top: calc(50% + 100px);
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 10;
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 2px 8px;
`;

export default function Liveness() {
  // navigate
  const navigate = useNavigate();
  // recoil
  const ocrFaceInfo = useRecoilValue(ocrFaceInfoState); // 안면인식 OCR 정보
  const deviceInfo = useRecoilValue(deviceInfoState); // 접속 디바이스 정보
  const channelInfo = useRecoilValue(channelInfoState); // 접속 채널 정보
  // state
  const [isLoading, setIsLoading] = useState(true); // 로딩 스피너
  const [loadingMessage, setLoadingMessage] = useState('잠시만 기다려 주세요\n카메라를 여는 중 입니다.'); // 로딩 메시지
  const [openModal, setOpenModal] = useState(false); // 에러모달
  const [modalTitle, setModalTitle] = useState('에러 발생'); // 에러 제목
  const [modalMessage, setModalMessage] = useState('에러가 발생하였습니다.\n잠시 후에 다시 시도해 주세요.'); // 에러 메시지
  const [openCameraAuthModal, setOpenCameraAuthModal] = useState(false); // 카메라 권한 에러 모달
  const [openConnectionError, setOpenConnectionError] = useState(false); // 통신에러 모달
  const [faceDirection, setFaceDirection] = useState(null); // 얼굴 방향 (0: front, 1: left, 2: right)
  const [faceInGuideLine, setFaceInGuideLine] = useState(null); // 얼굴이 인식 가능 상태인지 flag
  const [successCount, setSuccessCount] = useState(0); // 안면인식 성공 횟수
  const [count, setCount] = useState(3); // 정적모드 카운트 다운 (3 -> 1 까지 카운트)
  const [readyToTake, setReadyToTake] = useState(false); // 정적모드 촬영 준비 flag
  // ref
  const initialValues = useRef({}); // 안면인식 라이브러리 초기 값
  const faceChecker = useRef(null); // 안면인식 라이브러리
  const videoRef = useRef(null); // 안면인식 비디오 태그
  const isTakingPhoto = useRef(false); // 정적모드 촬영 중 flag
  const staticModeImage = useRef(null); // 정적 모드 캡처이미지
  // hook
  const { tmmPageSend } = useCalledBy();
  const { webClose } = useClose();
  /**-------------------------------- useEffect --------------------------------------*/
  useEffect(() => {
    // TMM 채널 페이지 이동 메시지 전달
    tmmPageSend(8);

    /*liveness 라이브러리 설정 값*/
    initialValues.current = {
      video: document.querySelector('video#face_verify'), // video 태크
      modelUrl: '/js/blazeface/model.json', // model.json
      directionChangeInterval: 300, // 얼굴 방향 전환시 딜레이 ms
      faceCheckCount: 2, // 얼굴 체크 검증 횟수 (default: 3)
      resolution: { width: 1280, height: 720 }, // 해상도
      onVideoReady,
      onPermissionDenied,
      onDetectionFailed,
      onDetectionComplete,
      setGuideLine: channelInfo.faceMode === 'S' ? setGuideLineInStaticMode : setGuideLine,
      setDirection,
      onFaceCheckSuccess,
      timeout: 20000, // timeout 시간 default: (15000ms 15초)
      record: true, // 녹화여부,
      os: deviceInfo.os === OS_TYPE.IOS ? 'ios' : 'aos'
    };

    // 안면인식 모듈 초기화
    initializeFaceModule();

    const handleMoveBack = () => {
      if (faceChecker.current) {
        // 안면인식 라이브러리 종료
        faceChecker.current.terminate();
      }
    };
    window.addEventListener('popstate', handleMoveBack);

    return () => {
      window.removeEventListener('popstate', handleMoveBack);
    };
  }, []);

  /*안면인식 모듈 초기화*/
  const initializeFaceModule = () => {
    if (faceChecker.current) {
      faceChecker.current.terminate();
    }
    // liveness 가동
    if (channelInfo.faceMode === FACE_MODE.DYNAMIC) {
      faceChecker.current = new FaceChecker().getFaceDirection(initialValues.current);
    } else {
      faceChecker.current = new FaceChecker().getFaceCapture(initialValues.current);
    }
  };

  /**-------------------------------- apis --------------------------------------*/
  /*얼굴인식 신분증 비교 인증 API*/
  const authenticateWithFace = async (result) => {
    try {
      if (!result.images || result.images.length < 1) {
        // 결과 이미지 배열 validation. 동적 안면인식 이미지 결과는 3장, 정적은 1장이 정상.
        throw new BizError('얼굴 인증 이미지 생성 중 에러가 발생하였습니다. 다시 시도해 주세요.', {
          faceMode: channelInfo.faceMode,
          imageCount: result.images?.length
        });
      }

      setLoadingMessage('잠시만 기다려주세요.\n얼굴 정보를 확인 중 입니다.');
      setIsLoading(true);
      const data = {
        rivsRqstId: sessionStorage.getItem(RivConstant.REQUEST_ID), // 비대면인증시스템요청ID
        prevRivsRqstId: channelInfo.prevRivsRqstId,
        osType: deviceInfo.os,
        image: removeBase64Prefix(channelInfo.faceMode === FACE_MODE.STATIC ? result.images[0] : result.images[2]), // 이미지 배열 중 정면 사진 비교
        idImage: ocrFaceInfo.photoIdImageFileNm,
        untactType: ocrFaceInfo.cardType,
        deviceName: getDeviceModel()
      };

      const response = await authenticateWithFaceApi(data);
      if (response.header.rsltCode === '0000') {
        console.log('===== [authenticateWithFace] ===== response: ', response);

        if (!response.payload || !response.payload.mkfrUuid) {
          // 안면인식 동영상 저장용 UUID 확인
          console.error('===== [얼굴인식 응답 mkfrUuid is null] =====');
          throw new BizError('얼굴인식 요청 UUID가 누락되었습니다. 다시 시도해 주세요.', {});
        }
        // 얼굴인식 동영상 업로드
        await uploadFaceVideo(result, response.payload.mkfrUuid);
      } else if (response.header.rsltCode === '9999') {
        console.error('===== [얼굴인식 신분증 비교 인증 API 에러] ===== response.header', response.header);
        setIsLoading(false);
        setModalTitle('얼굴 인증 실패');
        setModalMessage('에러가 발생하였습니다. 다시 시도해 주세요.');
        setOpenModal(true);
      } else {
        console.error('===== [얼굴인식 신분증 비교 인증 API 에러] ===== response.header', response.header);
        setIsLoading(false);
        setModalTitle(response.header.rsltMsgeTitl);
        setModalMessage(response.header.rsltMsgeCntn);
        setOpenModal(true);
      }
    } catch (error) {
      console.error('===== [authenticateWithFace 통신에러] ===== error: ', error);
      setIsLoading(false);
      if (error instanceof BizError) {
        webViewLogsApi(error.getWebViewLogDto('[liveness - authenticateWithFace]'));
        setModalTitle('얼굴 인증 실패');
        setModalMessage(error.message);
        setOpenModal(true);
      } else {
        webViewLogsApi(new RivError(error).getWebViewLogDto('[liveness - authenticateWithFace]'));
        setOpenConnectionError(true);
      }
    }
  };

  /*얼굴인식 동영상 업로드 API*/
  const uploadFaceVideo = async (result, mkfrUuid) => {
    try {
      // 안면인식 동영상, 이미지 결과 생성 에러 확인
      if (!result || !result.blob || !result.blob.mimeType || !result.blob.data || result.blob.data.size < 1) {
        throw new BizError('안면인식 동영상 생성 에러', {
          mimeType: result.blob?.mimeType || null,
          videoSize: result.blob?.data?.size || null
        });
      }

      const rivsRqstId = sessionStorage.getItem(RivConstant.REQUEST_ID);
      console.log('===== [uploadFaceVideo] ====== rivsRqstId: ', rivsRqstId);
      console.log('===== [uploadFaceVideo] ====== mkfrUuid: ', mkfrUuid);

      // 요청 헤더
      const reqHeader = { rivsRqstId, mkfrUuid };
      // 이전 요청 ID
      const { prevRivsRqstId } = channelInfo;
      const formData = new FormData();
      /**
       * 동영상 저장하는 MediaRecorder 는 브라우저에 따라 지원하는 동영상 형식이 다름.
       * 1. IOS 의 경우 mp4 우선, mp4가 지원 안되면 webm 확장자
       * 2. 안드로이드의 경우 webm 우선, webm 이 지원 안되면 mp4 우선 저장
       * */
      console.log('===== [uploadFaceVideo] ====== blob.mimeType: ', result.blob.mimeType);
      const fileName = result.blob.mimeType === FACE_MIME_TYPE.MP4 ? `${rivsRqstId}.mp4` : `${rivsRqstId}.webm`;
      console.log('===== [uploadFaceVideo] ====== fileName: ', fileName);
      formData.append('file', result.blob.data, fileName);
      formData.append('prevRivsRqstId', prevRivsRqstId);

      const response = await uploadFaceVideoApi(formData, reqHeader);

      if (response.header.rsltCode === '0000') {
        navigate(paths.step6, { replace: true });
      } else {
        console.error('===== [얼굴인식 동영상 업로드 API 에러] ===== response.header', response.header);
        setModalTitle('얼굴인식 동영상 업로드 에러');
        setModalMessage('얼굴인식 동영상 업로드 중 에러가 발생하였습니다.\n다시 시도해 주세요.');
        setOpenModal(true);
      }
    } catch (error) {
      console.error('===== [uploadFaceVideo 통신에러] ===== error: ', error);
      if (error instanceof BizError) {
        webViewLogsApi(error.getWebViewLogDto('[liveness - uploadFaceVideo]'));
        setModalTitle('얼굴 인증 동영상 생성 실패');
        setModalMessage('얼굴 인증 동영상 생성 중 에러가 발생하였습니다. 다시 시도해 주세요.');
        setOpenModal(true);
      } else {
        webViewLogsApi(new RivError(error).getWebViewLogDto('[liveness - uploadFaceVideo]'));
        setOpenConnectionError(true);
      }
    } finally {
      setIsLoading(false);
    }
  };
  /**-------------------------------- liveness --------------------------------------*/
  /*현재 진행중인 얼굴 기울기 체크 진행 방향을 매개변수로 전달*/
  const setDirection = (direction) => {
    /**
     * 0. front
     * 1. left
     * 2. right
     */
    console.log('===== [setDirection] ===== direction: ', direction);
    setFaceDirection(direction);
  };

  /*비디오가 준비되어 얼굴 탐지를 시작할 때 콜백함수*/
  const onVideoReady = () => {
    setIsLoading(false);
  };

  /*한화생명앱 카메라 권한 요청 브릿지통신 콜백 핸들러*/
  window.cameraAuthCallback = (response) => {
    console.log('===== [cameraAuthCallback 호출] =====');
    if (response) {
      const jsonResponse = decodeURIComponent(response);
      const parsedResponse = JSON.parse(jsonResponse);
      const parsedData = JSON.parse(parsedResponse.resData);
      console.log('===== [cameraAuthCallback] ====== parsedResponse: ', parsedResponse);
      console.log('===== [cameraAuthCallback] ====== parsedData: ', parsedData);
      // 안면인식 모듈 초기화
      initializeFaceModule();
    }
  };

  /*비디오 권한을 획득하지 못했을 때 발생하는 콜백함수*/
  const onPermissionDenied = () => {
    setIsLoading(false);
    if (channelInfo.channel === CHANNEL_CODE.HCI && deviceInfo.os === OS_TYPE.ANDROID) {
      // 한화생명 앱에서 안드로이드 접속 할 경우 권한 요청을 위해 브릿지 통신 사용함.
      const args = {
        permissions: ['android.permission.CAMERA', 'android.permission.RECORD_AUDIO'],
        title: '카메라 권한 요청',
        content:
          '비대면 인증을 위해 신분증과 얼굴을 촬영해야\n합니다.\n카메라 이용을 위해 휴대폰 설정 > 어플리케이션에서 해당 앱의 권한을 허용해 주세요.'
      };
      NativeBridge.call('wallet', 'requestPermission', args, 'cameraAuthCallback');
    } else {
      setOpenCameraAuthModal(true);
    }
  };

  /*일정 시간동안 얼굴이 감지되지 않아 실패일 때 콜백함수*/
  const onDetectionFailed = async () => {
    try {
      const data = {
        rivsRqstId: sessionStorage.getItem(RivConstant.REQUEST_ID),
        rivsBswrDvsnCode: channelInfo.channel
      };
      // 사용자 화면 얼굴인식 타임아웃 로그 적재 API 호출
      await detectionFailApi(data);
    } catch (error) {
      console.error('===== [onDetectionFailed 통신에러] ===== error: ', error);
    }
    setModalTitle('인증 실패하였습니다');
    setModalMessage(
      channelInfo.faceMode === FACE_MODE.DYNAMIC
        ? '얼굴을 인식할 수 없습니다.\n화면 상단 화살표에 맞게 얼굴을 좌우로 돌려주세요.'
        : '얼굴을 인식할 수 없습니다. 다시 시도해 주세요.'
    );
    setOpenModal(true);
  };

  /*체크 완료 후 콜백함수*/
  const onDetectionComplete = async (result) => {
    console.log('===== [onDetectionComplete] ===== result: ', result);
    // 얼굴인식 신분증 비교 인증 API 호출
    await authenticateWithFace(result);
  };

  /*얼굴이 인식 영역에 들어왔을 때 발생하는 콜백함수*/
  const setGuideLine = (status) => {
    // status: boolean / true: 인색, false: 비인식
    setFaceInGuideLine(status);
    console.log('===== [setGuideLine] ===== status: ', status);
  };

  /*정적모드 - 얼굴이 인식 영역에 들어왔을 때 발생하는 콜백함수*/
  const setGuideLineInStaticMode = async (status) => {
    setFaceInGuideLine(status);

    // 얼굴 인식이 된 상태 && 촬영중이 아닐때 실행
    if (status && !isTakingPhoto.current) {
      isTakingPhoto.current = true;
      setReadyToTake(true);
      // 촬영 카운트 다운 시작
      for (let i = 0; i < 3; i++) {
        await delay(700);
        setCount((prevState) => prevState - 1);
      }
      setReadyToTake(false);
      setCount(3);
      /**
       * 이미지 캡처 및 동영상 저장을 위한 stop 함수 호출
       * stop 함수 호출 시 onDetectionComplete 로 이미지 와 동영상 return
       * */
      faceChecker.current.stop();
    }
  };

  /*딜레이 함수*/
  const delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  /*얼굴 체크 성공 회수를 전달하는 콜백 함수*/
  const onFaceCheckSuccess = (count) => {
    setSuccessCount(count);
    console.log(`${count}회 성공`);
  };
  /**-------------------------------- 이벤트 헨들러 --------------------------------------*/
  /*얼굴인식실패 모달 - 홈으로 클릭이벤트*/
  const handleGoHomeClick = () => {
    navigate(paths.home);
  };

  /*에러 모달 - 재촬영 클릭이벤트*/
  const handleRetakeClick = () => {
    setOpenModal(false);
    setLoadingMessage('잠시만 기다려 주세요\n카메라를 여는 중 입니다.');
    setIsLoading(true);
    // 안면인식 성공 count 초기화
    setSuccessCount(0);

    if (channelInfo.faceMode === FACE_MODE.STATIC) {
      // 촬영중 flag off
      isTakingPhoto.current = false;
    }
    // 안면인식 재시도 함수 호출
    faceChecker.current.retry();
    setIsLoading(false);
  };

  /*카메라 권한 에러 모달 - 재촬영 클릭이벤트*/
  const handleCameraAuthRetakeClick = () => {
    setOpenCameraAuthModal(false);
    // 안면인식 성공 count 초기화
    setSuccessCount(0);
    setLoadingMessage('잠시만 기다려 주세요\n카메라를 여는 중 입니다.');
    setIsLoading(true);
    // 안면인식 모듈 초기화 (권한 획득 실패 시 재시도가 아닌 초기화 필요)
    initializeFaceModule();
    // 정적모드 촬영중 flag off
    isTakingPhoto.current = false;
    setIsLoading(false);
  };

  /*가이드 화살표 class 명 getter*/
  const getDirectionClass = () => {
    switch (faceDirection) {
      case 1:
        return 'FaceArrowLeft';
      case 2:
        return 'FaceArrowRight';
      default:
        return 'FaceArrowNone';
    }
  };

  /*타이틀 getter*/
  const getTitle = () => {
    switch (faceDirection) {
      case 0:
        return '정면을 바라봐 주세요';
      case 1:
        return '얼굴을 왼쪽으로 조금만 돌려주세요';
      case 2:
        return '얼굴을 오른쪽으로 조금만 돌려주세요';
      default:
        return '';
    }
  };

  /*FaceInfo 메시지 getter*/
  const getFaceInfoMessage = () => {
    switch (faceDirection) {
      case 0:
        return (
          <Faceinfo>
            정면을
            <br />
            바라봐 주세요
          </Faceinfo>
        );
      case 1:
        return (
          <Faceinfo>
            얼굴을 왼쪽으로
            <br />
            돌려주세요
          </Faceinfo>
        );
      case 2:
        return (
          <Faceinfo>
            얼굴을 오른쪽으로
            <br />
            돌려주세요
          </Faceinfo>
        );
    }
  };

  /*통신에러모달 - 확인 클릭이벤트*/
  const handleConnectionExitClick = () => {
    setOpenConnectionError(false);
    webClose();
  };
  /**-------------------------------- scripts --------------------------------------*/
  const getDeviceModel = () => {
    switch (deviceInfo.os) {
      case OS_TYPE.IOS:
        return 'iPhone';
      case OS_TYPE.ANDROID:
        return 'Android';
      default:
        return 'etc';
    }
  };

  return (
    <>
      <BgRoot>
        <SubBox>
          <Maskimg>
            <FaceLoader class="face-circle" width={window.innerWidth}>
              <svg
                className="FaceLoading"
                width="360"
                height="640"
                viewBox="0 0 360 640"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  id="circle"
                  className={readyToTake ? 'play' : ''}
                  x="20"
                  y="160"
                  width="320"
                  height="320"
                  rx="160"
                  stroke="white"
                  strokeOpacity="0.3"
                  strokeWidth="9"
                />
              </svg>
            </FaceLoader>

            {faceInGuideLine ? <FaceChkSucess /> : <FaceChkError />}
            <CameraVideo id="face_verify" playsInline muted ref={videoRef} />

            {channelInfo.faceMode === 'D' && (
              <>
                <FaceArrow width={window.innerWidth} height={window.innerHeight}>
                  <svg
                    className={getDirectionClass()}
                    width="66"
                    height="64"
                    viewBox="0 0 66 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      id="arrow1"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M65.699 6.28486C66.0939 6.67089 66.1012 7.30402 65.7151 7.69898L42.3747 31.5792C42.3746 31.5793 42.3745 31.5794 42.3744 31.5795C42.1352 31.8255 42 32.1577 42 32.5044C42 32.8516 42.1352 33.1837 42.3742 33.4296C42.3743 33.4297 42.3745 33.4299 42.3747 33.4301L65.715 57.3009C66.1011 57.6958 66.094 58.3289 65.6991 58.715C65.3042 59.1011 64.6711 59.094 64.285 58.6991L40.943 34.8266L40.9414 34.8249C40.3371 34.2041 40 33.3707 40 32.5044C40 31.6385 40.3372 30.8051 40.9414 30.1843L40.9429 30.1828L64.2849 6.30102C64.6709 5.90606 65.304 5.89882 65.699 6.28486Z"
                    />
                    <path
                      id="arrow2"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M45.699 6.28486C46.0939 6.67089 46.1012 7.30402 45.7151 7.69898L22.3747 31.5792C22.3746 31.5793 22.3745 31.5794 22.3744 31.5795C22.1352 31.8255 22 32.1577 22 32.5044C22 32.8516 22.1352 33.1837 22.3742 33.4296C22.3743 33.4297 22.3745 33.4299 22.3747 33.4301L45.715 57.3009C46.1011 57.6958 46.094 58.3289 45.6991 58.715C45.3042 59.1011 44.6711 59.094 44.285 58.6991L20.943 34.8266L20.9414 34.8249C20.3371 34.2041 20 33.3707 20 32.5044C20 31.6385 20.3372 30.8051 20.9414 30.1843L20.9429 30.1828L44.2849 6.30102C44.6709 5.90606 45.304 5.89882 45.699 6.28486Z"
                    />
                    <path
                      id="arrow3"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M25.699 6.28486C26.0939 6.67089 26.1012 7.30402 25.7151 7.69898L2.37467 31.5792C2.37457 31.5793 2.37447 31.5794 2.37438 31.5795C2.13516 31.8255 2 32.1577 2 32.5044C2 32.8516 2.13515 33.1837 2.37416 33.4296C2.37433 33.4297 2.3745 33.4299 2.37467 33.4301L25.715 57.3009C26.1011 57.6958 26.094 58.3289 25.6991 58.715C25.3042 59.1011 24.6711 59.094 24.285 58.6991L0.943013 34.8266L0.941373 34.8249C0.337121 34.2041 7.10939e-07 33.3707 7.48805e-07 32.5044C7.86654e-07 31.6385 0.337197 30.8051 0.941373 30.1843L0.942878 30.1828L24.2849 6.30102C24.6709 5.90606 25.304 5.89882 25.699 6.28486Z"
                    />
                  </svg>
                </FaceArrow>
                <CheckList width={window.innerWidth} height={window.innerHeight}>
                  <li>
                    <span className={successCount > 0 ? 'on' : 'off'}></span>
                  </li>
                  <li>
                    <span className={successCount > 1 ? 'on' : 'off'}></span>
                  </li>
                </CheckList>
              </>
            )}
            {faceDirection !== null && channelInfo.faceMode === 'D' && getFaceInfoMessage()}
            {readyToTake && <TextCount>{count}</TextCount>}
            <TextTitle>{channelInfo.faceMode === 'S' ? '정면을 바라봐 주세요' : getTitle()}</TextTitle>
            {faceDirection !== null && channelInfo.faceMode === 'D' && (
              <Textinfo width={window.innerWidth} height={window.innerHeight}>
                원활한 촬영을 위해 초록색을 유지해 주시고, 빨간색은 얼굴 촬영이 진행되지 않으니 유의해 주세요.
              </Textinfo>
            )}
            {channelInfo.faceMode === 'S' && (
              <Textinfo width={window.innerWidth} height={window.innerHeight}>
                자동 촬영이 시작됩니다. 원활한 촬영을 위해 초록색을 유지해 주시고, 빨간색은 얼굴 촬영이 진행되지 않으니
                유의해 주세요.
              </Textinfo>
            )}
          </Maskimg>
        </SubBox>
      </BgRoot>
      {isLoading && <Loading description={loadingMessage} />}
      {openModal && (
        <Dialog
          title={modalTitle}
          description={modalMessage}
          buttonContents={[
            {
              text: '홈으로',
              fill: 'neutral1',
              onClick: handleGoHomeClick
            },
            {
              text: '재촬영',
              fill: 'primary',
              onClick: handleRetakeClick
            }
          ]}
        />
      )}
      {openCameraAuthModal && (
        <Dialog
          title={'카메라 권한 요청'}
          description={
            '비대면 인증을 위해 신분증과 얼굴을 촬영해야\n합니다.\n카메라 이용을 위해 휴대폰 설정 > 어플리케이션에서 해당 앱의 권한을 허용해 주세요.'
          }
          buttonContents={[
            {
              text: '홈으로',
              fill: 'neutral1',
              onClick: handleGoHomeClick
            },
            {
              text: '재촬영',
              fill: 'primary',
              onClick: handleCameraAuthRetakeClick
            }
          ]}
        />
      )}
      {openConnectionError && (
        <Dialog
          title={'통신 에러 발생'}
          description={'통신 에러가 발생하였습니다.\n잠시 후에 다시 시도해 주세요.'}
          buttonContents={[
            {
              text: '확인',
              fill: 'primary',
              onClick: handleConnectionExitClick
            }
          ]}
        />
      )}
    </>
  );
}
