/**!
 * 한화생명 네이티브 앱 브릿지 인터페이스
 */
export const NativeBridge = {
  /**
   * @param command (호출하는 네이티브 서비스)
   * @param args (호출하는 네이티브 서비스에 전달할 arguments)
   * @param callback (네이티브 서비스에서 콜백 받을 함수명)
   * */
  async call(pluginId, command, args, callback) {
    let jsonObject = {
      pluginId,
      command,
      args,
      callbackScriptName: callback
    };

    let query = btoa(encodeURIComponent(JSON.stringify(jsonObject)));

    if (window.AndroidBridge && window.AndroidBridge.callNativeMethod) {
      await window.AndroidBridge.callNativeMethod(`native://callNative?${query}`);
    } else if (window.webkit && window.webkit.messageHandlers.callNative) {
      await window.webkit.messageHandlers.callNative.postMessage(query);
    }
  }
};
