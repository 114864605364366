export const RivConstant = {
  ACCESS_TOKEN_KEY: 'Riv-Access-Token',
  REQUEST_ID: 'REQUEST-ID'
};

/*채널 코드 정보*/
export const CHANNEL_CODE = {
  // 한화생명 앱 채널코드 'HCI'
  HCI: 'HCI'
};

/*by 코드 정보*/
export const BY_CODE = {
  // 보이는TM 호출
  TMM: 'TMM'
};

/*비대면인증 결과 코드*/
export const RESULT = {
  // 주민등록증 'JUMIN'
  SUCCESS_JUMIN: 'JUMIN',
  // 운전면허증 'DRIVER'
  SUCCESS_DRIVER: 'DRIVER',
  FAIL: 'N'
};

/*OCR 신분증 타입 코드*/
export const CARD_TYPE = {
  // 주민등록증 '1'
  JUMIN: '1',
  // 운전면허증 '2'
  DRIVER: '2'
};

/*OS 타입*/
export const OS_TYPE = {
  IOS: 'IOS',
  ANDROID: 'AND',
  LINUX: 'LIX',
  WINDOWS: 'WIN',
  ETC: 'ETC'
};

/*디바이스 타입*/
export const DEVICE_TYPE = {
  PC: 'PC',
  MOBILE: 'MOBILE'
};

/*안면인식 모드*/
export const FACE_MODE = {
  // 정적모드 'S'
  STATIC: 'S',
  // 동적모드 'D'
  DYNAMIC: 'D'
};

/*안면인식 MIME 타입*/
export const FACE_MIME_TYPE = {
  MP4: 'video/mp4',
  WEBM: 'video/webm'
};

/*보이는TM에 전달하는 현재 페이지 정보*/
export const PAGE_INFO = [
  {
    number: 1,
    title: '비대면인증 안내',
    description: '신분증 촬영 전 고객 안내 화면'
  },
  {
    number: 2,
    title: '신분증 촬영 안내',
    description: '신분증 촬영 시 유의 사항 안내 페이지'
  },
  {
    number: 3,
    title: '신분증 촬영',
    description: '신분증 촬영을 위한 카메라 프레임 화면'
  },
  {
    number: 4,
    title: '주민등록증 정보확인',
    description: '촬영된 주민등록증 정보를 표출해 주는 화면'
  },
  {
    number: 5,
    title: '운전면허증 정보확인',
    description: '촬영된 운전면허증 정보를 표출해 주는 화면'
  },
  {
    number: 6,
    title: '얼굴 인증 안내',
    description: '얼굴인증 안내 메시지 표출 및 개인(신용)정보 수집 및 이용동의 화면'
  },
  {
    number: 7,
    title: '얼굴 인증 팁 페이지',
    description: '얼굴인증 인식률을 높이기 위한 가이드 페이지'
  },
  {
    number: 8,
    title: '얼굴 촬영',
    description: '얼굴 인증 촬영을 위한 카메라 프레임 화면'
  },
  {
    number: 9,
    title: '본인인증 완료',
    description: '비대면 인증의 결과를 출력하는 화면'
  }
];
