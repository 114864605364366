import CryptoJS from 'crypto-js';

const iv = `${process.env.REACT_APP_API_IV}`;
const secret = `${process.env.REACT_APP_API_KEY}`.padEnd(16, '\0');

export const aes256Encrypt = (plainStr) => {
  try {
    if (plainStr !== null && plainStr !== '') {
      const plainTextBytes = CryptoJS.enc.Utf8.parse(plainStr);

      const keyBytes = CryptoJS.enc.Utf8.parse(secret);
      const ivBytes = CryptoJS.enc.Hex.parse(iv);

      const encrypted = CryptoJS.AES.encrypt(plainTextBytes, keyBytes, {
        iv: ivBytes,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });

      const encryptedBase64 = encrypted.ciphertext.toString(CryptoJS.enc.Base64);

      return encryptedBase64;
    }
  } catch (error) {
    console.error('인코딩 에러 발생:', error.message);
  }

  return plainStr;
};

export const aes256Decrypt = (encryptedStr) => {
  try {
    if (encryptedStr !== null && encryptedStr !== '') {
      const encryptedBytes = CryptoJS.enc.Base64.parse(encryptedStr);

      const keyBytes = CryptoJS.enc.Utf8.parse(secret);
      const ivBytes = CryptoJS.enc.Hex.parse(iv);

      const decryptedBytes = CryptoJS.AES.decrypt({ ciphertext: encryptedBytes }, keyBytes, {
        iv: ivBytes,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });

      const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);

      return decryptedText;
    }
  } catch (error) {
    console.error('AES 복호화 에러:', error.message);
  }

  return null;
};
