// react
import React, { useState } from 'react';

// components
import Dialog from 'src/components/Dialog';
import ProgressBar from './ProgressBar';

// style
import styled from '@emotion/styled';
import closeIcon from 'src/assets/images/close.svg';
import { useClose } from 'src/hooks/use-close';

const ActionIcon = styled.div`
  width: 28px;
  height: 28px;
`;
const Titlearea = styled.div`
  flex: 1;
  height: 27px;
`;

const Container1 = styled.div`
  align-self: stretch;
  flex: 1;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--spacing-150) var(--spacing-250);
  gap: var(--spacing-150);
`;

const HeaderRoot = styled.div`
  width: 100%;
  position: relative;
  height: 52px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

function Header({ step }) {
  const [openExitModal, setOpenExitModal] = useState(false);
  const { webClose } = useClose();
  /**-------------------------------- 이벤트 헨들러 --------------------------------------*/
  /*종료버튼 클릭이벤트*/
  const handleExitClick = () => {
    setOpenExitModal(true);
  };

  /*종료확인모달 - 확인 클릭이벤트*/
  const handleExitConfirm = () => {
    setOpenExitModal(false);
    webClose();
  };

  /*종료확인모달 - 취소 클릭이벤트*/
  const handleExitCancel = () => {
    setOpenExitModal(false);
  };

  return (
    <HeaderRoot>
      <Container1>
        <ActionIcon />
        <Titlearea />
        <ActionIcon />
        <ActionIcon onClick={handleExitClick}>
          <img src={closeIcon} alt="Close" />
        </ActionIcon>
      </Container1>

      <ProgressBar step={step} />
      {openExitModal && (
        <Dialog
          title="인증 종료"
          description={'비대면 인증 서비스가 완료되지 않았습니다.\n인증서비스를 종료합니다.'}
          buttonContents={[
            {
              text: '취소',
              fill: 'neutral1',
              onClick: handleExitCancel
            },
            {
              text: '확인',
              fill: 'primary',
              onClick: handleExitConfirm
            }
          ]}
        />
      )}
    </HeaderRoot>
  );
}

export default Header;
