const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const getPath = (path) => {
  return `${API_BASE_URL}${path}`;
};

const endpoint = {
  getToken: getPath('/rmtIdVer/getToken'), // 토큰 발급
  tokenVerify: getPath('/wv/rmtIdVer/verifyRivToken'), // 토큰 검증
  doRmtIdVer: getPath('/wv/rmtIdVer/doRmtIdVer'), // 신분증 진위확인 API
  personalInfo: getPath('/wv/metsakuur/updateAgrmYn'), // 얼굴인식 개인정보이용동의 업데이트 API
  mkfrVerify: getPath('/wv/metsakuur/mkfrVerify'), // 얼굴인식 신분증 비교 인증 API
  detectionFail: getPath('/wv/metsakuur/detection-fail'), // 사용자 화면 얼굴인식 타임아웃 로그 적재 API
  uploadFaceVideo: getPath('/wv/metsakuur/lvnsVideoUpload'), // 얼굴인식 동영상 업로드 API
  webViewLogs: getPath('/wv/metsakuur/logs') // 웹뷰 로그 적재 API
};

export default endpoint;
