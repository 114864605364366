// react
import { useEffect, useState } from 'react';

// recoil
import { useSetRecoilState } from 'recoil';
import { channelInfoState, deviceInfoState } from 'src/recoil/recoil';

// apis
import { validateTokenApi } from 'src/apis/apis';

// libraries
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

// hooks
import { useCalledBy } from 'src/hooks/use-called-by';

// constants
import { paths } from 'src/routes/path';
import { RivConstant } from 'src/constants/rivConstant';

// utils
import { detectDevice, detectOs, detectIOSVersion } from 'src/utils/commonUtils';

// components
import Header from 'src/components/Header';
import Button from 'src/components/Button';
import Dialog from 'src/components/Dialog';
import Copyright from 'src/components/Copyright';
import TextTitle from 'src/components/TextTitle';
import ButtonGroup from 'src/components/ButtonGroup';

// style
import styles from 'src/assets/app.module.css';
import { useClose } from 'src/hooks/use-close';

const Textinfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  & item {
    display: flex;
    gap: 24px;
    font-size: 16px;
    line-height: 150%;
    font-family: HanwhaGothic;
    color: #485059;
    text-align: left;
    padding: 18px 24px;

    & span {
      display: block;
      font-size: 14px;
      line-height: 150%;
      font-family: HanwhaGothic;
      color: var(--text-body2);
      text-align: center;
    }
  }
`;

export default function Step1() {
  // navigate
  const navigate = useNavigate();
  /**-------------------------------- query string --------------------------------------*/
  const [searchParams] = useSearchParams();
  const queryToken = searchParams.get('authorization'); // Query String 토큰 추출
  const calledBy = searchParams.get('by'); // Query String 'by' 추출 (특수한 스크립트나 로직 필요할 경우 사용)
  const queryRivsRqstId = searchParams.get('rivsRqstId'); // Query String 비대면인증시스템요청ID 추출
  const sessionToken = sessionStorage.getItem(RivConstant.ACCESS_TOKEN_KEY); // session에 저장되어있는 토큰
  const sessionRivsRqstId = sessionStorage.getItem(RivConstant.REQUEST_ID); // session에 저장되어있는 비대면인시스템요청ID
  /**-------------------------------- states --------------------------------------*/
  // recoil
  const setDeviceInfo = useSetRecoilState(deviceInfoState); // 접속 디바이스 정보
  const setChannelInfo = useSetRecoilState(channelInfoState); // 접속 채널 정보
  // state
  const [openModal, setOpenModal] = useState(false); // 중요 에러모달(확인시 서비스 종료)
  const [modalMessage, setModalMessage] = useState('토큰 검증 에러가 발생하였습니다.\n다시 시도해 주세요.'); // 에러 메시지
  const [isValidating, setIsValidating] = useState(true); // Token Validating 진행 중 flag
  const [openConnectionError, setOpenConnectionError] = useState(false); // 통신에러 모달
  // hooks
  const { tmmPageSend } = useCalledBy();
  const { webClose } = useClose();

  /**-------------------------------- useEffect --------------------------------------*/
  useEffect(() => {
    console.log(
      `===== [Query String Params] =====
      queryToken: ${queryToken}
      queryRivsRqstId: ${queryRivsRqstId}
      calledBy: ${calledBy}
      sessionToken: ${sessionToken}
      sessionRivsRqstId: ${sessionRivsRqstId}`
    );
    // 접속 기기 환경 감지
    detectDeviceInfo();

    // Query String 에 없는 경우 세션에있는 정보 사용 (에러모달에서 홈으로 올때 query string 초기화 떄문에 사용)
    const token = queryToken || sessionToken;
    const rivsRpstId = queryRivsRqstId || sessionRivsRqstId;

    if (!token || !rivsRpstId) {
      console.error(`===== [토큰 또는 요청 ID 데이터 추출 Fail.] ====`);
      setIsValidating(false);
      setModalMessage('토큰 또는 요청 ID 추출에 실패하였습니다.\n다시 시도해 주세요.');
      setOpenModal(true);
    } else {
      // 토큰 검사 호출
      validateToken(token, rivsRpstId);
    }
  }, []);

  /**-------------------------------- scripts --------------------------------------*/
  /*접속 디바이스 감지*/
  const detectDeviceInfo = () => {
    const os = detectOs(navigator.userAgent);
    const device = detectDevice(navigator.userAgent);
    const iosVersion = detectIOSVersion(navigator.userAgent);
    console.log(`===== [접속 디바이스 감지] ===== userAgent: ${navigator.userAgent}`);
    console.log(`===== [접속 디바이스 감지] ===== OS: ${os}, Device: ${device}, iosVersion: ${iosVersion}`);
    setDeviceInfo({ os, device, iosVersion });
  };
  /**-------------------------------- apis --------------------------------------*/
  /*토큰 검증 API 호출*/
  const validateToken = async (token, rivsRqstId) => {
    const reqData = { token, rivsRqstId };
    try {
      const response = await validateTokenApi(reqData);

      if (response.header.rsltCode === '0000') {
        sessionStorage.setItem(RivConstant.ACCESS_TOKEN_KEY, token);
        sessionStorage.setItem(RivConstant.REQUEST_ID, rivsRqstId);

        const { channel } = response.payload;
        const { ocrUseYn } = response.payload;
        const { faceUseYn } = response.payload;
        const { faceStopYn } = response.payload;
        const { prevRivsRqstId } = response.payload;
        const { faceMode } = response.payload;

        console.log(
          `===== [validateTokenApi] ===== 
          channel: ${channel}
          ocrUseYn: ${ocrUseYn}
          faceUseYn: ${faceUseYn}
          faceStopYn: ${faceStopYn}
          calledBy: ${calledBy}
          prevRivsRqstId: ${prevRivsRqstId}
          faceMode: ${faceMode}`
        );
        setChannelInfo({
          channel,
          chnlRedrctAddr: response.payload.chnlRedrctAddr,
          ocrUseYn,
          faceUseYn,
          faceStopYn,
          calledBy,
          prevRivsRqstId,
          faceMode
        });

        if ((ocrUseYn === 'N' && faceUseYn === 'Y') || (faceUseYn === 'Y' && faceStopYn === 'Y')) {
          /* 조건: 채널에서 얼굴인증만 사용하거나, 최근 24시간 이내에 OCR 인증 완료 후
                  얼굴인증 진행중에 이탈 이력 있으면 사용자 UX를 위해 -> 얼굴인증 페이지로 바로 이동 */
          navigate(paths.liveInfo, { replace: true });
        } else {
          setIsValidating(false);
        }
      } else {
        setIsValidating(false);
        console.error('===== [토큰 검증 API 에러] ===== response.header: ', response.header);
        setModalMessage('토큰 검증 에러가 발생하였습니다.\n다시 시도해 주세요.');
        setOpenModal(true);
      }
    } catch (error) {
      setIsValidating(false);
      console.error('===== [토큰 검증 API 통신에러] ===== error: ', error);
      setOpenConnectionError(true);
    }
  };

  useEffect(() => {
    // TMM 채널 페이지 이동 메시지 전달
    tmmPageSend(1);
  }, [channelInfoState.calledBy, tmmPageSend]);

  /**-------------------------------- 이벤트 헨들러 --------------------------------------*/
  /*시작하기 클릭이벤트*/
  const handleClickStart = () => {
    navigate(paths.step2);
  };

  /**
   * 토큰에러모달 - 확인 클릭이벤트
   * 토큰 에러시 더이상 진행 불가기에 exit 처리
   * */
  const handleModalClose = () => {
    setOpenModal(false);

    webClose();
  };

  /*통신에러모달 - 확인 클릭이벤트*/
  const handleConnectionExitClick = () => {
    setOpenConnectionError(false);
    webClose();
  };

  if (isValidating) {
    return <></>;
  }

  return (
    <>
      <div className={styles.cont_box}>
        <Header />
        <div className={styles.cont_body}>
          <div className={styles.ContWrap}>
            <TextTitle
              size="xlarge"
              type="title"
              title={`비대면 인증을 위해 \n준비해 주세요`}
              description={`고객님의 실명 확인을 확인할 수 있는 \n신분증 및 얼굴 촬영이 필요합니다.`}
            />

            <Textinfo>
              <item>
                <img src="/images/img-step1a.svg" alt="info img" />
                <p>
                  신분증
                  <span>주민등록증 또는 운전면허증</span>
                </p>
              </item>
              <item>
                <img src="/images/img-step1b.svg" alt="info img" />
                <p>
                  휴대폰
                  <span>본인 명의 기기</span>
                </p>
              </item>
            </Textinfo>
          </div>

          <div className={styles.btm_box}>
            <ButtonGroup gap="true" main>
              <Button text="시작하기" fill="primary" size="large" status="active" onClick={handleClickStart} />
            </ButtonGroup>
            <Copyright />
            {openModal && (
              <Dialog
                title="에러 발생"
                description={modalMessage}
                buttonContents={[
                  {
                    text: '확인',
                    fill: 'primary',
                    onClick: handleModalClose
                  }
                ]}
              />
            )}
            {openConnectionError && (
              <Dialog
                title={'통신 에러 발생'}
                description={'통신 에러가 발생하였습니다.\n잠시 후에 다시 시도해 주세요.'}
                buttonContents={[
                  {
                    text: '확인',
                    fill: 'primary',
                    onClick: handleConnectionExitClick
                  }
                ]}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
