// recoil
import { channelInfoState } from 'src/recoil/recoil';
import { useRecoilValue } from 'recoil';

// constants
import { BY_CODE, PAGE_INFO } from 'src/constants/rivConstant';
import { useCallback } from 'react';

/**
 * 채널에서 요구하는 특정 스크립트 실행을 위한 Custom Hook
 * ex) 보이는 TM 서비스(원격 화면 상담)에서 융통모 채널을 통해 비대면인증 접속 시
 * 비대면인증 페이지가 원격 화면에 보이지 않아, 상담 진행을 위해 페이지 이동시 마다
 * 현재 페이지 정보를 넘겨줘야함.
 * */
export function useCalledBy() {
  const channelInfo = useRecoilValue(channelInfoState);

  /*페이지 정보에서 페이지 번호로 정보 조회*/
  const getPageInfoById = useCallback((pageNumber) => {
    return PAGE_INFO.find((page) => page.number === pageNumber);
  }, []);

  /*보이는 TM 진행중인 페이지 message 전송*/
  const tmmPageSend = useCallback(
    (pageNumber) => {
      if (channelInfo.calledBy === BY_CODE.TMM && window.parent.postMessage) {
        const message = {
          type: 'PAGE',
          payload: getPageInfoById(pageNumber)
        };
        console.log('===== [tmmMovePage] ====== message: ', message.payload);
        window.parent.postMessage(message, '*');
      }
    },
    [channelInfo.calledBy, getPageInfoById]
  );

  /*보이는 TM 페이지 닫기 message 전송*/
  const tmmCloseSend = useCallback(() => {
    if (channelInfo.calledBy === BY_CODE.TMM && window.parent.postMessage) {
      const message = { type: 'CLOSE' };
      console.log('===== [tmmCloseSend] ======');
      window.parent.postMessage(message, '*');
    }
  }, [channelInfo.calledBy]);

  return { tmmPageSend, tmmCloseSend };
}
